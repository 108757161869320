.modal-header,
.modal-footer{
  justify-content: center !important;
}

.textField input{
  font-size: 12px;
  padding: 8px 5px;
}
.textField label{
  font-size: 14px;
  padding: 5px 0;
}

.category_img {
  width: 30px !important;
  height: 30px !important;
}

.select_role {
  width: 100%;
  padding: 5px;
  margin-top: 5px;
}

.user_verified {
  font-size: 15px;
  color: #1b946c;
  margin: 0 0 2px 5px;
}