//
// _card.scss
//

.card {
  margin-bottom: $grid-gutter-width;
  //box-shadow: $box-shadow;
  //-webkit-box-shadow: 0px 2px 5px -3px #000000;
  //box-shadow: 0px 2px 5px -3px #000000;
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 15px;
  margin: 0 0 7px 0; /*rtl:7px 0 0 0 */
  font-weight: $fw-medium;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;
  font-size: 13px;
}
