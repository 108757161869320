.greenStepAdminPage {
  .accordion-body {
    padding: 0px !important;
  }

  .accordion-button::after {
    display: none !important;
  }

  .react-switch-bg {
    text-align: center;
  }
  .irrelevant_checkbox {
    color: #fff;
    line-height: 26px;
  }
  .irrel_text {
    font-size: 12px;
    color: #666;
    font-weight: normal;
  }

}

.greenStepAdminPageAdd {
  .irrelevant_checkbox {
    color: #fff;
    line-height: 26px;
  }
  .irrel_text {
    font-size: 12px;
    color: #666;
    font-weight: normal;
  }
  .row {
    margin-bottom: 20px;
    .col {
      display: flex;
      flex-direction: column;
    }
  }
  textarea {
    border-radius: 0.8rem;
  }
}