.greenStepPage {

  .headerRow {

    .categories {
      position: relative;
      margin-bottom: 30px;

      .nav-tabs-custom {
        border-bottom: 0 solid #818282;
        margin-bottom: 20px;
        display: flex;
        gap: 20px;
        flex-wrap: nowrap;

        &:before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 14px;
          width: calc(100% - 26px);
          height: 1px;
          background-color: #818282;
        }

        .nav-item {
          width: 33%;

          &:hover:after,
          &.active:after{
            transform: scale(1);
          }
          &.active{
            font-weight: bold;
          }
        }

        .nav-link {
          position: relative;
          width: 100%;
          height: 90px;
          font-size: 18px;
          cursor: pointer;
          color: #fff !important;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          border-radius: 10px;
          line-height: 1;
          gap: 20px;
          margin-bottom: 20px;
          overflow: hidden;
        }

        .nav-item[category='1'] {
          .nav-link {
            background-color: #57c87f;
          }
          &:after{
            background: #57c87f !important;
          }
          .leafIcon {
            color: #57c87f;
          }
          .progress {
            background-color: #40a763;
          }
        }
        .nav-item[category='2'] {
          .nav-link {
            background-color: #fda75e;
          }
          &:after{
            background: #fda75e !important;
          }
          .leafIcon {
            color: #fda75e;
          }
          .progress {
            background-color: #f7873e;
          }
        }
        .nav-item[category='3'] {
          .nav-link {
            background-color: #5172fb;
          }
          &:after{
            background: #5172fb !important;
          }
          .leafIcon {
            color: #5172fb;
          }
          .progress {
            background-color: #355fcb;
          }
        }

        .leafPercent {
          z-index: 9;
          font-size: 24px;
        }

        .progress {
          position: absolute;
          height: 100%;
          top: 0%;
          left: 0;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          z-index: 1;
        }

        .leafIcon {
          color: #fff;
          cursor: pointer;
          height: 40px;
          width: 40px;
          flex-shrink: 0;
          z-index: 9;
        }

        .leafName {
          font-size: 24px;
          font-weight: 500;
          white-space: nowrap;
          z-index: 9;
        }

      }

      @media (max-width: 500px) {
        .nav-tabs-custom {
          border: 0;
          display: flex;
          flex-direction: column;
          gap: 30px;
          .nav-link {
            font-size: 16px;
          }
        }
      }
      @media (max-width: 800px) {
        .leafName {
          font-size: 18px;
        }
      }
    }

    .filters {
      position: relative;
      background-color: #fff;
      border-radius: 0.8rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 70px;
      margin-bottom: 30px;

      .filter {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 25%;
        border-right: 1px solid;

        &.status{
          border-right: none;
        }

        span {
          font-weight: 700;
          font-size: 14px;
          padding-right: 10px;
          user-select: none;

        }
      }
    }

    .progressContainer {
      position: relative;
      margin-bottom: 60px;

      .progressBar {
        position: relative;
        display: grid;
        grid-template-columns: repeat(20, 1fr);
        align-items: center;
        height: 50px;
        background: #fff;
        border-radius: 10px;
        overflow: hidden;

        &:before {
          --width: calc(var(--p) * 1%);

          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 100%;
          background-color: #a9edc0;
          z-index: 0;
          border-radius: 0;
          transition: all 2s;
          animation: load 2s forwards linear;
        }

        @keyframes load {
          to {
            width: var(--width);
          }
        }

        span {
          border-right: 1px solid #dedcdc;
          z-index: 9;
          width: 100%;
          height: 100%;

          &.last {
            border: none;
          }
        }

        span:nth-child(6) {
          border-right: 2px solid #40a764;
        }
        span:nth-child(12) {
          border-right: 2px solid #f7873e;
        }
        span:nth-child(20) {
          border-right: 2px solid #355fcb;
        }
      }

      .footer {
        display: grid;
        position: absolute;
        top: 60px;
        width: 100%;
        grid-template-columns: repeat(20, 1fr);
        text-align: right;
        padding-left: 20px;
        padding-right: 0;

        span {
          color: #7a7a7a;
        }
      }
    }

    &.is-sticky {
      position: sticky;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 999;
      animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
      margin: 0;
      padding: 0;
      height: 300px;
      margin-bottom: 50px;

      .col {
        background-color: #fff;
        box-shadow: 0px 8px 8px 0 rgba(0, 0, 0, 0.25);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      .categories {
        margin-bottom: 30px;
        margin-top: 10px;

        .nav-tabs-custom .nav-link {
          height: 80px;
        }
      }

      .progressContainer {
        margin-bottom: 35px;
        .progressBar {
          background: #f6f6f6;
          height: 40px;
        }
        .footer {
          top: 50px;
        }
      }

      .filters {
        margin-bottom: 0;
      }

    }
  }

  &:has(.is-sticky) .bigGreenStepsRow,
  &:has(.is-sticky) .completedByCategory{
    margin-top: 260px;
  }

  &:has(.is-sticky) .greenStepSmallCategory{
    margin-top: 190px;
  }

  .filterRow {
    margin-bottom: 20px;
    margin-right: 0;
    color: #565958;
    font-size: 18px;

    > div {
      display: flex;
      justify-content: space-between;
      padding: 0 4px 0 4px;
    }

    .categories {
      display: flex;
      align-items: center;

      span {
        padding: 0 10px;
        cursor: pointer;

        &.active,
        &:hover {
          font-weight: 500;
        }
      }
    }

    .filters {
      display: flex;
      align-items: center;
      background-color: #fff;
      border-radius: 12px;
      border: 1px solid rgba(86, 89, 88, 0.5);
      padding: 0 0 0 10px;
      > svg {
        font-size: 18px;
        color: #565958;
        margin-right: 5px;
        margin-bottom: 2px;
      }
    }
  }

  .filterSmallGreenStepsRow {
    margin-bottom: 20px;
    color: #565958;
    font-size: 18px;

    > div {
      display: flex;
      justify-content: flex-end;
      padding: 0 4px 0 4px;
    }

    .filters {
      display: flex;
      align-items: center;
      background-color: #fff;
      border-radius: 12px;
      border: 1px solid #565958;
      padding: 0 0 0 10px;
      > svg {
        font-size: 18px;
        color: #565958;
        margin-right: 5px;
        margin-bottom: 2px;
      }
    }

  }

  .dropDownBox {
    display: flex;
    align-items: flex-end;
    margin-top: 5px;
    margin-bottom: 20px;

    label {
      font-weight: 400;
      font-size: 14px;
      padding-left: 5px;
    }

    .companyCompletedIcon{
      margin-bottom: 12px;
    }
  }

  .pdfContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px 0 5px 0;

    a {
      display: flex;
      align-items: center;
    }
    .pdf_name {
      margin: 0 20px 0 10px;
    }
    img {
      height: 30px;
    }
    svg {
      transform: none !important;
    }
    .pdfLink {
      margin-right: 20px;
      display: flex;
      gap: 10px;
    }
    .companyCompletedIcon {
      margin-right: 10px !important;
    }
  }

  .pdfContainerfromLink {
    margin: 20px 0;
    position: relative;
    display: block;
    height: 65px;
    label {
      position: relative;
      float: left;
      width: 100%;
      font-size: 15px;
      font-weight: normal;
    }
    input {
      position: relative;
      float: left;
      border-radius: 10px;
    }
    .companyCompleted {
      height: 34px;
      position: relative;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
    }
  }

  .companyCompletedIcon {
    position: relative;
    float: left;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
    border: 2px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;

    &:hover svg,
    &.true svg {
      display: block !important;
    }
    &.edit svg {
      font-size: 12px !important;
    }
    svg {
      font-size: 14px;
      transform: rotate(352deg);
      color: #fff;
      display: none;
    }
  }

  .completeBtn {
    position: relative;
    float: right;
    width: 100px;
    height: auto;
    border-radius: 0.8rem;
    margin-right: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    background-color: #4FC878;
    color: #fff;
    padding: 5px 20px;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .customCheckIcon {
    position: relative;
    float: left;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 5px;
    cursor: pointer;
    border: 1px solid #565958;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    pointer-events: none;

    svg {
      font-size: 12px;
      transform: rotate(352deg);
      color: #565958;
      display: block !important;
    }
  }

  .completedByCategory {

    .card {
      padding-top: 30px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .subCategoryTitle {
      font-size: 16px;
      padding-bottom: 10px;
      font-weight: 500;
    }

    .cardCompletedTitle {
      font-size: 18px;
      margin-left: 2%;
      margin-bottom: 0;
    }

    .companyLogo {
      position: relative;
      float: left;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      cursor: pointer;
      margin-top: 2px;
      margin-bottom: 0;
      background-position: 50%;
      background-size: contain;
      background-repeat: no-repeat;
      box-shadow: 0px 0px 2px 2px #eee;
    }

    .item {
      padding-left: 4px;
      margin-bottom: 25px;

      &.irrelevant .completedDoneTitle {
        max-width: calc(100% - 55px) !important;
      }
      &.irrelevant .completedDoneComment {
        margin-left: 70px;
      }
    }

    .completedDoneComment {
      margin-left: 32px;
    }

    .completedDoneSwitch {
      position: relative;
      float: left;
      cursor: pointer;
      width: 42px;
      .react-custom-switch {
        margin-right: 10px;
      }
    }

    .completedDoneIcon {
      position: relative;
      float: left;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      cursor: pointer;
      margin-top: 2px;
      margin-bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        font-size: 14px;
        transform: rotate(352deg);
        color: #fff;
      }
    }

    .companyCompletedIcon {
      margin-top: 2px;
      margin-bottom: 0;
      margin-right: 0;
    }

    .customDropDownYear {
      display: flex;
      gap: 20px;
      align-items: center;

      .react-select {
        margin-right: 10px;
      }
    }

    .completedGreenStep_62 {
      .completedComment {
        font-size: 16px;
        float: left;
        margin-left: 11px;
        max-width: 50%;
        margin-bottom: 0;
        margin-top: 0;
      }
    }

    .completedGreenStep_211 {
      .completedComment {
        font-size: 16px;
        float: left;
        margin-left: 11px;
        max-width: 50%;
        margin-bottom: 0;
        margin-top: 0;
      }
      .completedAddNewIcon.editCommentBtn svg {
        margin-bottom: 8px;
      }
    }

    .customGreenStep203Show {
      margin-bottom: 10px !important;
      p {
        padding: 0 0 5px 0;
      }
    }

    .customGreenStep203Completed {
      margin: 10px 0;
      height: auto;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .titleCopy {
        font-weight: 500;
      }

      .react-select {
        width: 70%;
        float: left;
      }
      .explanationField {
        width: 80%;
        float: left;
      }
      .explanationDiv {
        display: flex;
      }
      .companyCompletedIcon {
        margin-top: 10px;
        margin-left: 40px;
      }
    }

    .customGreenStep210Completed {
      input:not(.css-mohuvp-dummyInput-DummyInput) {
        height: 35px;
        width: 150px;
        background-color: #F3F3F3;
        border: none;
      }
      .inputCol {
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }

    .customGreenStep211Completed {
      input:not(.css-mohuvp-dummyInput-DummyInput) {
        height: 35px;
        background-color: #F3F3F3;
        border: none;
      }
      .inputCol {
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }

    .customGreenStep216Completed {
      input:not(.css-mohuvp-dummyInput-DummyInput) {
        height: 35px;
        width: 100%;
        background-color: #F3F3F3;
        border: none;
      }
      .inputCol {
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }

    .react-custom-switch {
      width: 40px;
      height: 21px;
      margin-right: 20px;

      &.active {
        .react-switch-handle {
          transform: translateX(20px) !important;
        }
      }

      .react-switch-bg {
        height: 100% !important;
        width: 100% !important;
        opacity: 0.4;
        background: rgba(141, 134, 130, 0.7);
      }
      .react-switch-bg div {
        display: none !important;
      }
      .react-switch-handle {
        height: 21px !important;
        width: 22px !important;
        top: 0px !important;
        background-color: rgba(116, 105, 97, 0.5) !important;
      }
      .react-switch-handle div {
        height: 20px !important;
        width: 20px !important;
      }
      .react-switch-handle div svg {
        font-size: 16px;
        color: #fff;
      }
    }

    .completedDoneTitle {
      font-size: 16px;
      float: left;
      margin-left: 11px;
      max-width: calc(100% - 36px);
      margin-bottom: 0;
      &.activeAddComment {
        .completedDoneTitleCopy {
          font-weight: 500;
        }
      }
    }

    .completedDoneDesc {
      display: block;
      width: 100%;
      float: left;
      padding-left: 32px;
      padding-top: 0;
      font-size: 14px;

      .pdfUploaderContainer {
        margin: 20px 0 5px 0;
      }
    }

    .completedAddNewIcon {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-left: 8px;
      border-radius: 50%;
      cursor: pointer;
      svg {
        font-size: 15px;
        margin-bottom: 7px;
        margin-left: 2.5px;
        color: #fff;
      }
      &.editCommentBtn svg{
        font-size: 13px;
        margin-left: 3.5px;
        margin-bottom: 4px;
      }

    }

    .completedCommentInputRow {
      width: 100%;
      float: left;
      margin-bottom: 5px;
      margin-left: 30px;
      margin-top: 10px;

      .companyCompletedIcon svg{
        font-size: 14px;
        transform: none;
        color: #fff;
        display: none;
      }
      .companyCompletedIcon.save {
        margin-top: 6px;
        margin-left: 10px;
      }
      .companyDoneInput {
        padding: 5px 20px;
        border-radius: 15px;
        width: 92%;
        float: left;
      }
    }

    .completedComment {
      width: 100%;
      float: left;
      margin-top: 8px;
      margin-bottom: 0;

      p {
        margin-bottom: 0;
      }

      .completedCommentName {
        position: relative;
        float: left;
        margin-right: 10px;
        font-size: 16px;
        margin-bottom: 0;
      }
      .completedCommentDate {
        font-size: 16px;
        font-weight: 200;
        margin-bottom: 0;
      }
      .completedCommentText {
        position: relative;
        float: left;
        max-width: 85%;
        margin-right: 10px;
      }

      .companyCompletedIcon {
        margin-right: 10px;
        svg{
          font-size: 14px;
          transform: none;
          color: #fff;
          display: none;
        }
      }

      .companyCompletedIcon.save {
        margin-top: 6px;
        margin-left: 10px;
      }
      .companyDoneInput {
        padding: 5px 20px;
        border-radius: 15px;
        width: 80%;
        float: left;
      }
    }

    .customCompletedCar {
      position: relative;
      float: left;
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      gap: 5px;

      label {
        position: relative;
        float: left;
        margin: 0 10px 0 0;
        font-weight: 400;
        white-space: nowrap;
      }

      input {
        width: 100px;
        margin-right: 20px;
      }

      .column {
        display: flex;
        flex-direction: column;
      }
    }

    .addMoreText {
      padding-left: 20px;
      cursor: pointer;
    }
    .customAddMoreYearInCompleted {
      position: relative;
      width: 100%;
      float: left;
      margin-top: 20px;
    }
  }

  .slider {
    margin-top: 10px;
    padding-bottom: 30px;
    .title {
      font-weight: 400;
    }
    &>span {
      height: 10px;
      color: #009448;
      float: left !important;
      width: 80% !important;
    }

    .sliderFinish {
      padding: 5px 0 5px 10px;
      display: block;
      float: left;
    }

    .MuiSlider-valueLabelOpen.MuiSlider-valueLabel {
      transform: translateY(150%) scale(1);
      background-color: #f6f6f6;
      color: #000;
      border-radius: 15px;
      min-width: 50px;
    }
    .MuiSlider-valueLabel:before {
      transform: translate(-50%, -315%) rotate(45deg);
    }
  }

  .customGreenStep {

    &.gs2 {
      .category, .title, .header {
        color: #FD974F;
      }
    }

    &.gs3 {
      .category, .title, .header, .comment a, .comment span {
        color: #4433CD;
      }
    }

    .header {
      font-size: 18px;
      font-weight: 500;
      color: #009448;
      text-align: left;
      margin-bottom: 20px;
    }

    .title {
      color: #009448;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    input:not(.css-mohuvp-dummyInput-DummyInput) {
      height: 35px;
      width: 150px;
      background-color: #F3F3F3;
      border: none;

      &.fullWidth {
        width: 100% !important;
      }
    }


    .percent {
      margin-left: 20px;
    }

    .comment {
      margin-top: 20px;
      color: #000;
      span {
        color: #009448;
      }
    }

    .desc {
      a {
        color: #6F6F6F;
        text-decoration: underline !important;
        padding-left: 5px;
      }
    }

    .items {
      display: flex;
      flex-direction: row;
      gap: 10px 15px;
      flex-wrap: wrap;

      .item {
        border: 1px solid #d9cdcd;
        border-radius: 20px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .save {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      .saveBtn {
        width: 100px;
        padding: 5px 20px;
        border-radius: 0.8rem;
        color: #fff;
        text-align: center;
        cursor: pointer;
        white-space: nowrap;
      }
    }

    .companyCompleted {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      -webkit-user-select: none;
      user-select: none;
      float: left;

      .companyCompletedTitle {
        font-size: 16px;
      }

      .companyCompletedIcon {
        position: relative;
        float: left;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 10px;
        cursor: pointer;
        border: 1px solid;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;

        &:hover svg,
        &.true svg {
          display: block !important;
        }
        &.edit svg {
          font-size: 12px !important;
        }
        svg {
          font-size: 14px;
          transform: rotate(352deg);
          color: #fff;
          display: none;
        }
      }
    }

    .users {
      display: flex;
      flex-direction: column;

      .userType {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
      }

      label {
        min-width: 82px;
        white-space: normal;
        margin-right: 10px;
      }
    }


    &.step2 {
      .category,
      .title {
        color: #4FC878;
      }
      .companyCompletedIcon {
        border-color: #009448;
      }
      .companyCompletedIcon:hover,
      .companyCompletedIcon.true {
        background-color: #009448;
      }
      .category {
        padding: 10px 0 0 0;
      }

    }
    &.step3 {
      .category, .title, .header {
        color: #FD974F;
      }
      .companyCompletedIcon {
        border-color: #FD974F;
      }
      .companyCompletedIcon:hover,
      .companyCompletedIcon.true {
        background-color: #FD974F;
      }
      .companyCompleted {
        margin-top: 20px;
      }
    }
    &.step4 {
      .category, .title, .header, .comment a, .comment span {
        color: #4433CD;
      }
      .companyCompletedIcon {
        border-color: #4433CD;
      }
      .companyCompletedIcon:hover,
      .companyCompletedIcon.true {
        background-color: #4433CD;
      }
      .companyCompleted {
        margin-top: 20px;
      }
    }
  }

  &[data-category='0'] {
    .categoryTextColor,
    .pdfUploaderContainer label,
    .slider>span,
    .MuiSlider-valueLabelOpen.MuiSlider-valueLabel,
    .showVideo svg {
      color: #4FC878 !important;
    }

    .categoryBgColor,
    .companyCompletedIcon:hover,
    .companyCompletedIcon.true,
    .react-custom-switch.active .react-switch-bg,
    .react-custom-switch.active .react-switch-handle,
    .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range
    {
      background-color: #4FC878 !important;
    }

    .categoryBorderColor,
    .pdfUploaderContainer {
      border-color: #4FC878 !important;
    }
  }

  &[data-category='1'] {
    .categoryTextColor,
    .pdfUploaderContainer label,
    .slider>span,
    .MuiSlider-valueLabelOpen.MuiSlider-valueLabel,
    .showVideo svg{
      color: #FD974F !important;
    }

    .categoryBgColor,
    .companyCompletedIcon:hover,
    .companyCompletedIcon.true,
    .react-custom-switch.active .react-switch-bg,
    .react-custom-switch.active .react-switch-handle
    {
      background-color: #FD974F !important;
    }

    .categoryBorderColor,
    .pdfUploaderContainer {
      border-color: #FD974F !important;
    }

    .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
      background-color: #FFDBC2 !important;
    }
  }

  &[data-category='2'] {
    .categoryTextColor,
    .pdfUploaderContainer label,
    .slider>span,
    .MuiSlider-valueLabelOpen.MuiSlider-valueLabel,
    .showVideo svg{
      color: #4433CD !important;
    }
    .react-datepicker .react-datepicker__day--in-range {
      color: #fff;
    }


    .categoryBgColor,
    .companyCompletedIcon:hover,
    .companyCompletedIcon.true,
    .react-custom-switch.active .react-switch-bg,
    .react-custom-switch.active .react-switch-handle
    {
      background-color: #4433CD !important;
    }

    .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
      background-color: #E3C9FC !important;
    }

    .categoryBorderColor,
    .pdfUploaderContainer {
      border-color: #4433CD !important;
    }
  }
}

.initialGreenStepsModal {
  .modal-content {
    border-radius: 0;
    align-items: center;
    justify-content: center;

    .modal-body {
      border: 1px solid #6F6F6F;
      width: 650px;
      height: 80%;
      max-height: 80%;
      border-radius: 15px;
      color: #6F6F6F;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: left;
      flex-direction: column;
    }

    .steps {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .step {
      display: none;

      .header {
        font-size: 26px;
        font-weight: 500;
        color: #009448;
        text-align: center;
        margin-bottom: 30px;
        line-height: 1.2;
      }

      .title {
        color: #009448;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
      }

      input {
        height: 30px;
        width: 150px;
        background-color: #F3F3F3;
        border: none;
        font-size: 16px;
      }

      .comment {
        margin-top: 20px;
        color: #000;
        span {
          color: #009448;
        }
      }

      .desc {
        a {
          color: #6F6F6F;
          text-decoration: underline !important;
          padding-left: 5px;
        }
      }

      .percent {
        margin-left: 20px;
      }

      .items {
        display: flex;
        flex-direction: row;
        gap: 10px 15px;
        flex-wrap: wrap;
        margin: 10px 0;

        .item {
          border: 1px solid #d9cdcd;
          border-radius: 20px;
          padding: 2px 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }


      .companyCompleted {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        float: left;

        .companyCompletedTitle {
          font-size: 16px;
        }

        .companyCompletedIcon {
          position: relative;
          float: left;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          margin-right: 10px;
          cursor: pointer;
          border: 1px solid;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 0;

          &:hover svg,
          &.true svg {
            display: block !important;
          }
          &.edit svg {
            font-size: 12px !important;
          }
          svg {
            font-size: 14px;
            transform: rotate(352deg);
            color: #fff;
            display: none;
          }
        }
      }

      .users {
        display: flex;
        flex-direction: column;

        .userType {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 5px;
        }

        label {
          width: 85px;
        }
      }


      &.step1 {
        width: 60%;
      }

      &.step2 {
        .category,
        .title {
          color: #4FC878;
        }
        .companyCompletedIcon {
          border-color: #009448;
        }
        .companyCompletedIcon:hover,
        .companyCompletedIcon.true {
          background-color: #009448;
        }
        .category {
          padding: 10px 0 0 0;
        }

      }
      &.step3 {
        .category, .title, .header {
          color: #FD974F;
        }
        .companyCompletedIcon {
          border-color: #FD974F;
        }
        .companyCompletedIcon:hover,
        .companyCompletedIcon.true {
          background-color: #FD974F;
        }
        .companyCompleted {
          margin-top: 20px;
        }
      }
      &.step4 {
        .category, .title, .header, .comment a, .comment span {
          color: #4433CD;
        }
        .companyCompletedIcon {
          border-color: #4433CD;
        }
        .companyCompletedIcon:hover,
        .companyCompletedIcon.true {
          background-color: #4433CD;
        }
        .companyCompleted {
          margin-top: 20px;
        }
        //.comment {
        //  width: 60%;
        //}
      }
      &.step5 {
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .header {
          margin-bottom: 0;
        }

        img {
          height: 170px;
        }
        .btn {
          padding: 2px 30px;
          font-size: 16px;
          border-radius: 20px;
          background-color: #009448;
          color: #fff;
        }
      }
    }

    .modal-body.step_1 .step.step1,
    .modal-body.step_2 .step.step2,
    .modal-body.step_3 .step.step3,
    .modal-body.step_4 .step.step4{
      display: block;
    }

    .modal-body.step_5 .step.step5 {
      display: flex;
      button{
        display: none;
      }
    }

    .skip {
      position: absolute;
      top: 20px;
      left: 35px;
      text-align: right;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: #009448;
      }
    }

    .languageDropdown.dropdown {
      position: absolute;
      top: 0;
      right: 15px;
      display: block;
      width: 70px;
      height: 70px;
      z-index: 9999;
    }

    button:not(.languageDropdown button) {
      position: absolute;
      bottom: 30px;
      right: 35px;
      padding: 2px 30px;
      font-size: 16px;
      border-radius: 20px;
    }
    button.previous {
      right: 160px !important;
    }
  }
}

.greenStepVideoModal {
  .videoFrame {
    width: 100% !important;
    height: 400px !important;
    border-radius: 10px !important;
    overflow: hidden;
    border: 1px solid #878585;
  }

  .videoClose {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;

    svg {
      font-size: 30px;
      color: #009448;
    }
  }
}


.tourFistStep {

  .modal-content {
    border-radius: 15px !important;
  }

  .modal-body {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 30px;
  }

  .title {
    color: #009448;
    padding-top: 20px;
    font-weight: bold;
    font-size: 20px !important;
    padding-bottom: 20px;
  }

  .copy {
    font-size: 16px;
  }

  .copyBottom {
    font-size: 10px;
  }

  .btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 20px 0;

    .btn {
      border-radius: 15px !important;
      padding: 4px 25px !important;
      font-size: 14px !important;
    }
    .btn.skip {
      border: 1px solid #009448;
      color: #009448;
    }
    .btn.go {
      background-color: #009448 !important;
      color: #fff;
    }
  }
}