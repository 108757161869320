.companyStoriesList {
  .approveBtn {
    margin: 0 5px;
  }

  .actionsColumn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .statusBtn {
    text-transform: capitalize;
    &.approved {
      background-color: #009448;
    }

    &.rejected {
      background-color: #ff715b;
    }

    &.waiting {

    }
  }
}


