//
// _nav.scss
//

.nav-tabs,.nav-pills {
  > li {
      > a {
      color: $gray-700;
      font-weight: $fw-medium;
    }
  }
}

.nav-pills {
  > a {
      color: $gray-700;  
      font-weight: $fw-medium;
  }
}

.nav-tabs-custom {
  border-bottom: 2px solid $gray-300;

  .nav-item {
    position: relative;
    color: $dark;

    &::after {
      content: "";
      background: $primary;
      height: 4px;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: -1px;
      transition: all 250ms ease 0s;
      transform: scale(0);
    }

    &.active {
      color: $sidebar-menu-item-active-color;
      &:after {
        transform: scale(1);
      }
    }
    .nav-link {
      border: none;
    }
  }
}
