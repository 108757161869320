@import "./bootstrap.scss";
@import "./app.scss";

.react-bootstrap-table-page-btns-ul {
  margin-bottom: 0px;
}
.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
  span {
    display: none;
  }
}


.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}

.input-group-append,
.input-group-prepend {
  display: flex;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
    column-gap: 24px;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.steps a.nav-link {
  background-color: #c3cef8;
  color: #3b5de7;
}
.steps a.nav-link .number {
  border-color: #3b5de7;
}
.pagination .pagination {
  margin-bottom: 0px;
}


.task-box {
  border: 1px solid $border-color;
}


.react-select.has-error div{
  border-color: #ff715b;
}

.react-datepicker.has-error{
  border-color: #ff715b;
}

.swal-title {
  color: #009448;
}
.swal-footer {
  text-align: center;
}
.swal-button--danger {
  background-color: #FF3300 !important;
  color: #fff;
  font-weight: 400;
  border-radius: 20px;
  padding: 5px 30px;
  margin: 0 10px;
}
.swal-button.swal-button--cancel {
  background-color: #009448 !important;
  color: #fff;
  font-weight: 400;
  border-radius: 20px;
  padding: 5px 30px;
  margin: 0 10px;
}

.hover-effect:hover {
  filter: brightness(110%);
}



//tour to project
.react-joyride__tooltip {
  border-radius: 15px !important;

  h4 {
    color: #009448;
    padding-top: 20px;
    font-weight: bold;
    font-size: 20px !important;
  }

  b {
    font-weight: 700;
  }

  button {
    background-color: #009448 !important;
    border-radius: 15px !important;
    padding: 6px 25px !important;
    font-size: 14px !important;
  }
}


//Tooltip
.tooltip_environment {
  .tooltip-inner {
    background-color: #4FC878 !important;
  }
  .tooltip-arrow:before {
    border-bottom-color: #4FC878 !important;
  }
}
.tooltip_social {
  .tooltip-inner {
    background-color: #FD974F !important;
  }
  .tooltip-arrow:before {
    border-bottom-color: #FD974F !important;
  }
}
.tooltip_governance {
  .tooltip-inner {
    background-color: #4433CD !important;
  }
  .tooltip-arrow:before {
    border-bottom-color: #4433CD !important;
  }
}