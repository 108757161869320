.admin_companies_page {
  .card-header {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
  }

  .leaf_item {
    position: relative;
    float: left;
    width: 30px;
    height: 30px;
    padding: 0 0px;
    margin: 0;
    cursor: pointer;
  }

  .leaf_bg {
    position: relative;
    width: 100%;
    height: 100%;
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important;
    z-index: 99999;
  }

  .leaf_bg_custom {
    background-color: #009448;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    height: 100%;
    z-index: 2;
  }
  .leaf_bg_fill{
    background-color: rgb(164, 221, 188);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .leaf_item.not_approved .leaf_bg_custom {
    opacity: 0.7;
  }

  .leaf_not_approved {
    font-size: 12px;
  }

  .tooltipContainer {
    width: 80px;
    cursor: pointer;
    .progress {
      background-color: rgb(164, 221, 188);
      height: 20px;
      border-radius: 50px;
    }
  }
  #tooltipProgress {
    background-color: rgb(164, 221, 188);
    margin-top: 10px;
    margin-bottom: 40px;
  }

  .progressTooltip {
    position: absolute;
    top: 25px;
    right: -17px;
    color: #fff;
    font-size: 12px;
    width: 35px;
    border-radius: 6px;
    height: 18px;
    line-height: 18px;
    text-align: center;
  }

  .progressTooltip:before {
    //content: "▶";
    content: "";
    position: absolute;
    top: -6px;
    left: 37%;
    width: 11px;
    height: 11px;
    transform: rotate(45deg);
    font-size: 14px;
    border-top: 7px solid #4433CD;
    border-left: 6px solid #4433CD;
  }
}

.com_card {
  box-shadow: 0px 5px 5px 1px rgba(34, 60, 80, 0.2)!important;
  border-radius: 7px !important;
  cursor: pointer;
  transition: all 0.5s ease;
}

.com_card:hover {
  transform: scale(1.05) !important;
}

.card_price {
  position: relative;
  float: left;
  width: 150px;
  font-weight: bold;
  color: #000;
  font-size: 20px;
}

.root {
  width: 100%;
  margin-top: 10px;
  overflow-x: auto;
}

.img_item {
  width: 30px;
  height: 30px !important;
  background-size: contain !important;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.img_category {
  width: 25px !important;
  height: 25px !important;
  padding: 4px !important;
}

.leaf_comp {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  //transform: translateY(-40%);
  width: 100%;
}


.leaf_cm {
  position: relative;
  float: left;
  width: 20px;
  height: auto;
  padding: 0 0px;
  margin: 0;
  filter: grayscale(100%);
  opacity: 0.3;
}

.leaf_comp_1:first-child {
  filter: none;
  opacity: 1;
}

.leaf_comp_1 img:nth-child(-n+1),
.leaf_comp_2 img:nth-child(-n+2),
.leaf_comp_3 img:nth-child(-n+3),
.leaf_comp_4 img:nth-child(-n+4),
.leaf_comp_5 img:nth-child(-n+5){
  filter: none;
  opacity: 1;
}


.logo_c svg,
.action_c svg{
  display: none !important;
}

.company_category_btn {
  width: 100%;
  margin: 5px 0;
}
.company_category_img {
  position: relative;
  float: left;
  width: 30px;
  height: 30px;
}
.company_category_badge {
  position: absolute !important;
  top: -2px !important;
  right: 16px !important;
  font-size: 12px !important;
}


.company_cat_img_table {
  position: relative;
  float: left;
  width: 30px;
  height: 30px !important;
  background-size: contain !important;
  background-position: 50% 50%;
  margin-right: 5px;
}

.company_green_step_item {
  position: relative;
  top: 50%;
  left: 50%;
  height: 40px;
  width: 40px;
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;
}

.company_leaf_items {
  position: relative;
  width: 200px;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  gap: 10px;
}

.table tbody tr:hover .leaf-icon {
  color: #e1e1e1;
}

.leaf-icon {
  color: #f3f3f3;
  cursor: pointer;
  height: 37px;
  width: 37px;
  flex-shrink: 0;
}

.header_filter_select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border-color: #ced4da;
  border-radius: 5px;
  color: #636c76;
}

.categoriesOption {
  display: flex;
  gap: 10px;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
  }
}