//
// _header.scss
//

#page-topbar {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1002;
  padding-top: 30px;
}

.navbar-header {
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: $header-height;
  padding: 0 calc(#{$grid-gutter-width} / 2) 0 0;
  max-width: 100% !important;

  .child {
    display: flex;
    height: 100%;
  }

  .dropdown {
    .header-item.show {
      background-color: $gray-100;
    }
  }
}

.navbar-brand-box {
  padding: 0 1.5rem;
  text-align: center;
  width: $navbar-brand-box-width;

  .logo {
    position: relative;
    width: 100%;
    height: 100%;


    .logo-lg {
      position: absolute;
      top: 0;
      left: -15px;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
      display: block;
    }

    .logo-sm {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
      display: none;
    }

  }
}

.notificationIcon {
  position: relative;
  height: 70px;
  width: 40px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;

  svg {
   color: $main-color;
  }

  .badge {
    position: absolute;
    top: 22px;
    right: 14px;
    width: 14px;
    height: 14px;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    font-weight: 500;
  }
}

.languageDropdown {
  .header-toggle {
    height: 70px;
    display: flex;
    align-items: center;
    gap: 5px;

    svg {
      color: $main-color;
    }
    span {
      text-transform: uppercase;
    }
  }
}

.settingsDropdown {
  .header-toggle {
    height: 70px;
    display: flex;
    align-items: center;
    gap: 5px;

    svg {
      color: $main-color;
    }
  }
  .companyItem {
    font-weight: 500;
  }
  .dropdown-item {
    cursor: pointer;
  }
  .dropdown-item.active, .dropdown-item:active {
    color: #009448;
    font-weight: bold !important;
  }
}


.page-content {
  padding: calc(#{$header-height} + #{$grid-gutter-width / 2})
  $grid-gutter-width $footer-height $grid-gutter-width;
}

.header-item {
  height: $header-height;
  box-shadow: none !important;
  color: $header-item-color;
  border: 0;
  border-radius: 0px;

  &:hover {
    color: $header-item-color;
  }
}

.header-profile-user {
  height: 36px;
  width: 36px;
  background-color: $gray-300;
  padding: 3px;
}

.noti-icon {
  i {
    font-size: 22px;
    color: $header-item-color;
  }

  .badge {
    position: absolute;
    top: 12px;
    right: 5px;
  }
}

.notification-item {
  .d-flex{
    padding: 0.75rem 1rem;
    display: flex;

    &:hover {
      background-color: $gray-300;
    }
  }
}


@media (max-width: 992px) {
  .navbar-brand-box {
    width: 90px;
    padding: 0 1rem;
  }

  .logo {
    .logo-lg {
      display: none !important;
    }

    .logo-sm {
      display: block !important;
    }
  }
}

@media (max-width: 600px) {
  .navbar-header {
    .dropdown {
      position: static;

      .dropdown-menu {
        left: 10px !important;
        right: 10px !important;
      }
    }
  }
}

@media (max-width: 380px) {
  .navbar-brand-box {
    display: none;
  }
}
