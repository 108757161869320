.carbonPage {

  .card-body {
    padding: 0;
  }

  .greenText {
    color: #4FC878;
  }

  .link {
    color: #4FC878;
    cursor: pointer;
  }

  .initialPageContainer {
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
    min-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ECECEC;
    border-radius: var(--bs-card-border-radius);

    .title {
      font-weight: 500;
      font-size: 26px;
    }

    .copy {
      font-size: 18px;
    }

    .step {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 10px;
    }

    .step1 {
      .legal {
        font-size: 16px;
        margin-top: 20px;
      }
    }

    .step2 {
      p {
        margin: 0;
      }

      .scope {
        font-size: 16px;
        padding-top: 10px;
        margin-left: 20px;
      }

      .scopeDesc {
        display: list-item;
        list-style-type: disc;
        line-height: 26px;
        margin-left: 20px;

        &::marker {
          color: #4FC878;
          font-size: 26px;
        }
      }

    }

    .btn {
      position: absolute;
      bottom: 30px;
      right: 50px;
      background-color: #4FC878;
      color: #fff;
      padding: 5px 15px;
    }


  }

  .createCarbonMainContainer {
    display: flex;
    flex-direction: row;
    min-height: 600px;
    background-color: #ECECEC;
    border-radius: var(--bs-card-border-radius);

    &.step_0 {
      .sideBar {
        justify-content: flex-end;
      }
    }

    .sideBar {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: #4FC878;
      width: 35%;
      color: #fff;
      border-top-left-radius: 0.8rem;
      border-bottom-left-radius: 0.8rem;

      .menu {
        position: relative;
        display: block;
        padding: 0;
        height: 50px;
        background-color: #ECECEC;
        border-top-left-radius: 0.8rem;

        .scope {
          position: relative;
          float: left;
          height: 100%;
          width: 33.3%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #50c878;
          cursor: pointer;
          font-size: 16px;
          font-weight: 500;
        }

        .scope:hover {
          font-weight: 500;
        }

        .scope.active {
          background-color: #50c878;
          color: #fff;
        }

        .scope1.active {
          border-top-left-radius: 0.8rem;
        }
        .scope3.active {
          border-top-right-radius: 0.8rem;
        }

      }

      .footer {
        padding: 30px 40px;
        font-size: 16px;

        .title {
          margin-bottom: 5px;
          font-size: 20px;
        }
      }
    }

    .steps {
      width: 65%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;

      .head {
        color: #4FC878;
        padding-bottom: 10px;
        font-size: 16px;
      }

      .title {
        font-size: 32px;
        font-weight: 500;
        color: #4FC878;
        padding-bottom: 20px;
      }

      .legal {
        font-size: 14px;
        margin-top: 20px;
      }

      label {
        font-weight: 400;
        font-size: 16px;
      }

      input {
        height: 30px;
      }

      b {
        font-weight: 500;
      }

      .step {
        width: 100%;
        padding-left: 10%;
        padding-right: 5%;
      }

      .step1 {
        .radioInputsRow {
          display: flex;
          gap: 30px;

          .radioCheck {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;

            input {
              height: 1em;
              margin: 0;
              border: 1px solid #4FC878;
              background-color: #fff;
              cursor: pointer;

              &:checked {
                background-color: #4FC878 !important;
              }
            }

            label {
              margin: 0;
              cursor: pointer;
            }
          }
        }
      }

      .step_1_2 {
        .desc div {
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;

          label {
            margin: 0;
          }
        }
      }

      .step_2_1 {
        .desc div {
          display: flex;
          gap: 20px;

          label {
            color: #888383;
          }
        }
      }

      .step_2_3 {
        .desc div {
          display: flex;
          gap: 20px;

          label {
            color: #888383;
          }
        }
      }

      .step_3_1 {
        padding-top: 20px;
        padding-bottom: 80px;
        .row {
          margin: 10px 0;
          &>div {
            padding: 0 10px 0 0;
          }
          .col-1 {
            display: flex;
            align-items: center;
            color: #888383;
          }
        }
        .wasteClass {
          color: #4FC878;
          font-size: 16px;
          margin: 20px 0 10px 0;
        }
        .addNewWaste {
          color: #4FC878;
          font-size: 16px;
          margin: 10px 0;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 5px;

          svg {
            font-size: 16px;
          }
        }
        .removeWaste {
          color: #4FC878 !important;
        }
      }



    }

    .btns {
      position: absolute;
      bottom: 30px;
      right: 50px;
      display: flex;
      flex-direction: row;
      gap: 10px;

      .btn {
        background-color: #4FC878;
        color: #fff;
        padding: 5px 15px;
      }

      .save {
        background-color: #fff;
        color: #4FC878;
      }
    }

    .form-control.has-error {
      border: 1px solid #ff715b;
    }
  }

  .resultPageContainer {
    padding: 50px 0 20px 50px;
    min-height: 500px;

    .headerMenu {
      display: flex;
      flex-direction: row;
      gap: 50px;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 30px;

      .item {
        font-weight: 500;
        cursor: pointer;
      }

      .active {
        color: #4FC878;
        padding: 0 10px;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          height: 2px;
          width: 100%;
          bottom: 0;
          left: 0;
          background-color: #4FC878;
        }
      }
    }

    .mainChartBox {
      width: 60%;

      .title {
        font-size: 22px;
        color: #4FC878;
        font-weight: 500;
      }
      .dropdown {
        width: 200px;
        color: #009448;
        margin: 20px 0px;
      }
    }

    .totalBox {
      .chart {
        height: 220px;
        .chartContainer {
          height: 100%;
          overflow: visible;
        }
      }
      .sumColumn {
        color: #4FC878;
      }
      .title {
        font-weight: 500;
        font-size: 24px;
      }
      .sum {
        font-size: 16px;
        font-weight: 500;
        margin: 10px 0 20px 0;
      }
      .scopeSum {
        font-size: 16px;
        margin: 10px 0;
        span {
          font-weight: 500;
        }
      }
    }

    .scopeBox {
      margin-top: 50px;
      --bs-gutter-x: 0;

      &.activeStep_1 .content{
        border-top-left-radius: 0 !important;
      }

      .menu {
        position: relative;
        display: block;
        padding: 0;
        height: 50px;
        border-top-left-radius: 0.8rem;
        width: 60%;

        .scope {
          position: relative;
          float: left;
          height: 100%;
          width: 33.3%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #50c878;
          cursor: pointer;
          font-size: 18px;
          font-weight: 500;
        }

        .scope:hover {
          font-weight: 700;
        }

        .scope.active {
          background-color: #50c878;
          color: #fff;
        }
        .scope.disabled {
          opacity: 0.5;
          pointer-events: none;
        }

        .scope1.active {
          border-top-left-radius: 0.8rem;
        }
        .scope3.active {
          border-top-right-radius: 0.8rem;
        }

      }

      .content {
        background-color: #4FC878;
        border-radius: 0.8rem;
        color: #fff;

        .chart {
          height: 200px;
        }

        &.currentItem_3 .row{
          align-items: flex-start !important;
          justify-content: space-between;

          .chartContainer {
            margin-top: 50px;
          }
        }

        .row {
          display: flex;
          align-items: center;
          justify-content: center;
          --bs-gutter-x: 0;
        }

        .row.edit {
          min-height: 40px;
          line-height: 1;
          text-align: right;
          padding: 20px 50px 40px 0;
          font-size: 18px;
          text-decoration: underline;
          cursor: pointer;
        }

        .sumColumn {
          font-size: 16px;
          display: flex;
          flex-direction: column;
          gap: 2px;
          padding: 40px 50px 0px 50px;
          justify-content: center;
          box-sizing: border-box;
        }

        .title {
          font-size: 20px;
          font-weight: 500;
        }

        .scopeSum {
          color: #fff;
          margin: 0 0 10px 0;
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          &.last {
           flex-direction: column;
            align-items: flex-start;
            gap: 0px;
          }

          &.editCon {
            .value {
              display: flex;
              align-items: center;
              gap: 10px;
            }
            .result {
              display: flex;
              align-items: center;
              height: 35px;
            }
            &.first .value{
              width: 230px;
            }
            &.last .value{
              width: 180px;
            }
          }

          input {
            width: 120px;
            border-radius: 20px !important;
            line-height: 1 !important;
          }

          .key {
            margin-bottom: 2px;
            font-weight: 500;
            font-size: 20px;
          }
          .value {
            min-width: 70px;
            float: left;
            font-size: 16px;
            width: 150px;
            display: flex;
          }
          &.first .value {
            width: 200px;
          }
          &.second .value {
            width: 200px;
          }
          .result {
            font-weight: 500;
            font-size: 16px;
            width: 200px;
            margin: 0 10px;
          }

          .scopeSumLastItems {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
          }
        }

        .sum {
          font-weight: 500;
        }

        .sliderContainer {
          display: flex;
          gap: 10px;
          align-items: center;
          justify-content: center;

          .slider {
            width: 200px;
            border-radius: 20px;
            background-color: rgba(255,255,255, 0.3);
            height: 20px;
            overflow: hidden;

            .sliderComplete {
              background-color: rgba(255,255,255, 0.7);
              height: 100%;
              border-radius: 20px;
            }
          }

          .sliderSum {
            width: 66px;
          }
        }

      }

      .apexcharts-tooltip {
        color: #009448;
        text-transform: capitalize;
      }

    }

    .recharts-wrapper svg{
      overflow: inherit !important;
    }

    .addNewWastes {
      margin-top: 40px;

      .row {
        min-height: 50px !important;
      }

      input {
        line-height: 1;
        border-radius: 20px !important;
      }
    }

    .addNewWaste {
      cursor: pointer;
    }

    .footer {
      margin-top: 40px;
      padding-right: 40px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;

      .btn {
        background-color: #4FC878;
        color: #fff;
        padding: 5px 20px;
        width: auto;
        white-space: nowrap;
      }

      .continueBtn {
        background-color: #fff;
        border: 1px solid #4FC878;
        color: #4FC878;
        padding: 5px 15px;
        width: auto;
      }
    }

  }




  .recharts-tooltip-wrapper {
    z-index: 999;
    .recharts-default-tooltip {
      border-radius: 10px;
      z-index: 999;
      background-color: #fff;
      .recharts-tooltip-label {}
      .recharts-tooltip-item-list {
        .recharts-tooltip-item {
          .recharts-tooltip-item-name {}
          .recharts-tooltip-item-separator {}
          .recharts-tooltip-item-value {}
          .recharts-tooltip-item-unit {}
        }
      }

    }
  }



}