.announcementPage {

  .card-title {
    margin: 25px 0 0px 0;
    
    .row {
      margin-top: 20px;
      margin-left: 50px;
    }
    .form_datepicker{
      height: 38px;
      min-height: 38px;
      border-radius: 20px;
      &:focus {
        box-shadow: 0 0 0 1px #009448;
      }
    }

    .react-datepicker__close-icon::after {
      background-color: #009448;
    }
    .react-datepicker__current-month {
      color: #009448;
      font-weight: 500;
      font-size: 1rem;
      display: none;
    }
    .react-datepicker__month-select,
    .react-datepicker__year-select{
      border-radius: 15px;
      color: #009448;
      border-color: #009448;
      margin: 5px 0;
      font-size: 14px;
    }
    .react-datepicker__navigation-icon--previous::before,
    .react-datepicker__navigation-icon--next::before{
      border-radius: 2px;
    }
    .react-datepicker__navigation-icon {
      top: 3px;
    }
    .react-datepicker__navigation {
      top: 8px;
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected{
      border-radius: 50%;
      background-color: #009448;
      color: #fff;
    }
    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover {
      border-radius: 50%;
    }
  }

  .card-footer {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    .arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #009448;
      font-weight: bold;
      font-size: 36px;
      cursor: pointer;

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }

  .activityLogPage {
    .containerRow {
      margin-bottom: 40px;

      .logRow {
        margin: 5px 0;
        color: #333333;
        font-size: 16px;
        &.desc {
          margin-left: 50px;
        }
      }
      .logDate {
        color: rgba(51,51,51,0.5);
        font-weight: 500;
        margin-left: 50px;
      }
      .logType {
        width: 50px;
        height: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        float: left;
        &>img {
          width: 20px;
        }
        &>svg {
          width: 20px;
          color: #009448;
        }
        &.green_step .leaf-icon {
          color: #fff;
        }
      }
      .logCategory {
        color: #000;
        font-weight: 500;
      }
      .logUser {
        color: #009448;
        font-weight: 500;
        padding-right: 10px;
      }
    }
  }

  .notificationPage {
    .containerRow {
      margin-bottom: 30px;

      &.notRead {
        box-shadow: 0px 0px 2px 4px #f7f7f7;
        background-color: #f7f7f7;
        padding: 5px 10px;
        cursor: pointer;
      }

      &.notRead:hover  {
        box-shadow: 0px 0px 2px 4px #eee;
        background-color: #eee;
        padding: 5px 10px;
        cursor: pointer;
      }

      .logRow {
        margin: 5px 0;
        color: #333333;
        font-size: 16px;
        &.desc {
          margin-left: 50px;
        }
        .categoryTextColor {
          font-weight: 500;
          cursor: pointer;
        }
        &.desc.mainIndex_1 {
          .categoryTextColor {
            color: #4FC878;
          }
        }
        &.desc.mainIndex_2 {
          .categoryTextColor {
            color: #FD974F;
          }
        }
        &.desc.mainIndex_3 {
          .categoryTextColor {
            color: #4433CD;
          }
        }
      }

      .logDate {
        color: rgba(51,51,51,0.5);
        font-weight: 500;
        margin-left: 50px;
      }

      .logType {
        width: 50px;
        height: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        float: left;
        gap: 10px;

        &>svg {
          width: 20px;
          color: #009448;
        }
        .badge {
          display: block;
          width: 10px;
          height: 10px;
          background-color: red;
          cursor: pointer;
        }
      }

      .logCategory {
        color: #000;
        font-weight: 500;
      }
    }
  }

}