
.snackbar-container{
  position: fixed;
  z-index: 1000;
  bottom: 20px;
  right: 10px;
  min-height: 50px;
  line-height: 1;
  padding: 5px 30px;
  min-width: 300px;
  max-width: 500px;
  border-radius: 6px;
  background-color: transparent;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  display: flex;
  justify-content: left;
  align-items: center;
  animation: fadein 0.5s, fadeout 0.5s, 30;
}

.success {
  color: #297a50;
  background-color: #daf5e7;
  border-color: #c7efda;
}
.error {
  color: #994437;
  background-color: #ffe3de;
  border-color: #ffd4ce;
}

.snackbar-button {
  position: absolute;
  top: 15px;
  right: 10px;
  height: 20px;
  width: 20px;
  font-size: 17px;
  text-align: center;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  line-height: 18px;

  &:hover {
    background-color: #ffffff;
  }
}

.snackbar-icon {
  position: absolute;
  top: -17px;
  left: 10px;
  font-size: 28px;
}
