.greenStepSmallCategory {

  .greenStepListCard {
    //transition: all 0.5s ease;
    min-height: 275px;
    transition: transform 0.5s;
    transform-style: preserve-3d;
    padding: 20px 20px 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;

    &.showAdd,
    &.showDesc {
      transform: rotateY( 180deg );
      transition: transform 0.5s;

      .cardShowMore {
        display: none;
      }

      .cardBack{
        visibility: visible;
        opacity: 1;
        height: 100%;
      }

      .cardFront{
        visibility: hidden;
        opacity: 0;
        height: 0;
      }
    }

    &.showAdd {
      .listCardDesc,
      svg.backIcon{
        display: none !important;
      }
      .listShowMoreLess{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: absolute;
        bottom: -65px;
      }
    }

    &.showDesc {
      .commentRow,
      svg.checkIcon,
      svg.backIcon2{
        display: none !important;
      }
      .listShowMoreLess{
        justify-content: flex-start;
        bottom: 0;
        left: 0;
      }
    }

    &.showCalendar {
      z-index: 9;
    }

    .cardFront{
      width: 100%;
      text-align: left;
      backface-visibility: hidden;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      visibility: visible;
      opacity: 1;
      height: 100%;

      .listShowMoreLess {
        position: absolute;
        bottom: 14px;
        justify-content: flex-start;
        padding-left: 18px;
        gap: 10px;
      }

      .score {
        position: absolute;
        top: 25px;
        right: 50px;
        padding: 0;
        text-align: right;
        line-height: 0;
        font-size: 16px;
      }

      .favorite {
        position: absolute;
        top: 13px;
        right: 15px;
        padding: 0;
        text-align: right;
        line-height: 1;
        margin-bottom: 30px;
        cursor: pointer;
        .svg {
          cursor: pointer;
        }
      }

      .favoriteCalendar {
        position: absolute;
        top: 10px;
        right: 40px;
        width: auto;
        height: auto;
        z-index: 99999;
        border: 1px solid #aeaeae;
        border-radius: 15px;
        background-color: #fff;

        .header {
          font-weight: 500;
          color: #009448;
          font-size: 16px;
          padding-left: 20px;
          padding-top: 10px;
        }

        .footer {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          gap: 20px;
          padding-right: 20px;
          padding-bottom: 10px;

          .cancel {
            cursor: pointer;
            font-weight: 500;
          }

          .save {
            color: #009448;
            cursor: pointer;
            font-weight: 500;
          }
        }

        .react-datepicker {
          border-radius: 15px;
          border: none;

          .react-datepicker__header {
            height: 80px;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            background-color: #fff;
            //border-bottom: none;
          }

          .react-datepicker__month-select,
          .react-datepicker__year-select {
            padding: 3px 5px;
            border-radius: 20px;
            cursor: pointer;
          }

          .react-datepicker__navigation,
          .react-datepicker__current-month {
            display: none;
          }

          .react-datepicker__day--selected,
          .react-datepicker__day--in-selecting-range,
          .react-datepicker__day--in-range,
          .react-datepicker__month-text--selected,
          .react-datepicker__month-text--in-selecting-range,
          .react-datepicker__month-text--in-range,
          .react-datepicker__quarter-text--selected,
          .react-datepicker__quarter-text--in-selecting-range,
          .react-datepicker__quarter-text--in-range,
          .react-datepicker__year-text--selected,
          .react-datepicker__year-text--in-selecting-range,
          .react-datepicker__year-text--in-range {
            border-radius: 0.3rem;
            background-color: #a7eec0;
            color: #000;
          }

          .react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select, .react-datepicker__month-year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll {
            display: inline-block;
            margin: 0 20px 0 0;
          }
        }

      }

      .listTitle {
        text-align: left;
        font-size: 16px;
        font-weight: 400;
        width: 100%;
        margin-top: 10px;
      }

      .listDesc {
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;
      }
    }

    .cardBack{
      width: 100%;
      text-align: left;
      backface-visibility: hidden;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      transform: rotateY( 180deg ) !important;
      visibility: hidden;
      opacity: 0;
      height: 0;

      .companyCompletedInput {
        padding: 5px 20px;
        border-radius: 15px;
      }

      .companyCompletedLabel {
        font-size: 16px;
      }

      .commentRow {
        width: 100%;
      }

      .listCardDesc {
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 165px;
      }
    }

    .listShowMoreLess {
      position: relative;
      bottom: 0;
      left: 0;
      right: 20px;
      display: flex;
      justify-content: space-between;

      svg, div {
        cursor: pointer;
      }

      .showVideo {
        svg {
          width: 26px;
          height: 26px;
        }
      }

      .cardShowMore {
        cursor: pointer;
        text-decoration: underline;
        font-size: 16px;
      }
      .cardShowMore:hover {
        font-weight: 500;
      }
    }

  }


  .companyCompletedToggleBtns {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-bottom: 20px;

    .companyCompleted {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-right: 30px;
      user-select: none;
    }

    .companyCompletedTitle {
      font-size: 16px;
    }

    .react-custom-switch {
      width: 40px;
      height: 21px;
      margin-right: 20px;

      &.active {
        .react-switch-handle {
          transform: translateX(20px) !important;
        }
      }

      .react-switch-bg {
        height: 100% !important;
        width: 100% !important;
        opacity: 0.4;
        background: rgba(141, 134, 130, 0.7);
      }
      .react-switch-bg div {
        display: none !important;
      }
      .react-switch-handle {
        height: 21px !important;
        width: 22px !important;
        top: 0px !important;
        background-color: rgba(116, 105, 97, 0.5) !important;
      }
      .react-switch-handle div {
        height: 20px !important;
        width: 20px !important;
      }
      .react-switch-handle div svg {
        font-size: 16px;
        color: #fff;
      }
    }
  }

  .paginationRow {
    display: flex;
    flex-direction: row;
    gap: 50px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin-bottom: 100px;

    .arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #009448;
      font-weight: bold;
      font-size: 36px;
      cursor: pointer;

      span {
        font-size: 16px;
        color: #000;
      }
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }

}