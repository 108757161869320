.actionPlanPage {

  .goalsContent {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      margin-bottom: 10px;
      color: #009448;

      .title {
        font-size: 22px;
        font-weight: 500;
      }
      .all {
        font-size: 18px;
        cursor: pointer;
        padding-right: 10px;
      }
    }

    .addNewGoal {
      color: #009448;
      font-size: 18px;
      cursor: pointer;
      margin-top: 10px;
      display: flex;
      align-items: center;
      width: 300px;

      .addNewIcon {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-left: 8px;
        border-radius: 50%;
        cursor: pointer;
        background-color: #009448;
        flex-shrink: 0;
        svg {
          font-size: 15px;
          margin-top: 3px;
          margin-left: 2px;
          color: #fff;
          display: block;
        }
      }
    }

    .carousel {
      width: 100%;
      min-height: 50px;
      padding: 15px 0;
      text-align: left;
      display: flex;
      flex-direction: row;
      align-items: center;

      .carousel-inner {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-grow: 1;
        width: 100%;
      }
      .carousel-item {
        height: 100%;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        transition: all 0.5s ease;
      }
      .item {
        width: 30%;
        height: 100%;
        margin: 0;
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 15px;
        .item-icon {
          background-color: #009448;
          height: 100%;
          display: block;
          width: 8px;
          border-radius: 5px;
          min-height: 50px;
          flex-shrink: 0;
        }
      }

      .carousel-item.active, .carousel-item-next, .carousel-item-prev {
        display: flex !important;
      }

      .carousel-control-prev {
        justify-content: flex-start;
      }
      .carousel-control-next {
        justify-content: flex-end;
      }

      .carousel-control {
        width: 5% !important;
        opacity: 1;
        position: relative;

        &.false {
          pointer-events: none;
          span {
            opacity: 0.2;
          }
        }

        span {
          margin-top: 10px !important;
        }
        .carousel-control-prev-icon,
        .carousel-control-next-icon{
          width: 30px;
          height: 30px;
          background-image: none !important;
          position: relative;

          &:before,
          &:after {
            content:"";
            position: absolute;
            background: #009448;
            border-radius: 5px;
            display: block;
          }
        }


        &.left,
        &.right {
          .carousel-control-prev-icon,
          .carousel-control-next-icon{
            &:before {
              top: 0%;
            }
            &:after {
              bottom: 48%;
            }
            &:before,
            &:after {
              left: 0;
              height: 4px;
              width: 20px;
            }
          }
        }

        &.left {
          .carousel-control-prev-icon,
          .carousel-control-next-icon {
            &:before,
            &:after {
              transform: rotate(-45deg);
            }

            &:after {
              transform: rotate(45deg);
            }
          }
        }
        &.right {
          .carousel-control-prev-icon,
          .carousel-control-next-icon {
            &:before,
            &:after {
              transform: rotate(45deg);
            }

            &:after {
              transform: rotate(-45deg);
            }
          }
        }

      }
    }

    .item_all {
      .item {
        min-height: 50px;
        margin: 5px 0;
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        gap: 5px;
        .item-icon {
          background-color: #009448;
          display: block;
          height: 15px;
          width: 15px;
          border-radius: 50%;
          flex-shrink: 0;
          margin-top: 6px;
        }
        .item-copy {
          position: relative;
          float: left;
          margin-right: 10px;
        }
      }

      .btn_circle {
        margin-bottom: -4px;
        background-color: #009448 !important;
        display: inline-block;
        width: 18px;
        height: 18px;
        margin-left: 8px;
        border-radius: 50%;
        cursor: pointer;
        padding-top: 4px;
        padding-left: 4px;
      }
      .btn_circle.edit svg {
        font-size: 10px !important;
      }
      .btn_circle.delete {
        padding-left: 3px;
        padding-top: 3px;
        svg {
          transform: none;
        }
      }

      .btn_circle svg {
        font-size: 12px;
        transform: rotate(352deg);
        color: #fff;
        display: block;
      }
    }

    .goalsNotExist {
      .item {
        width: 100%;
        height: 100%;
        margin: 0;
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 5px;
        .item-icon {
          background-color: #009448;
          height: 100%;
          display: block;
          width: 8px;
          border-radius: 5px;
          min-height: 50px;
          flex-shrink: 0;
        }
        .item-copy {
          margin-left: 10px;
          font-size: 16px;
        }
      }
    }
  }

  .row.actionsType {
    margin-top: 40px;
    .title {
      color: #009448;
      font-size: 22px;
      font-weight: 500;
    }
    .type {
      display: flex;
      margin: 10px 0;
      gap: 8px;
      align-items: center;
      justify-content: flex-start;

      .list {
        color: #fff;
        background-color: #B8B1AC;
        border-radius: 4px;
        padding: 2px;
        font-size: 19px;
        cursor: pointer;
        margin-top: 1px;
        &.active {
          background-color: #009448;
        }
      }

      .calendar {
        font-size: 22px;
        color: #B8B1AC;
        cursor: pointer;
        &.active {
          color: #009448;
        }
      }

      .addNewActionPlan {
        color: #009448;
        display: flex;
        font-size: 16px;
        margin-left: 10px;
        align-items: center;
        cursor: pointer;

        .add {
          width: 18px;
          height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #009448;
          border-radius: 15px;
          padding: 0;
          color: #fff;
          font-size: 12px;
          cursor: pointer;
          margin-left: 5px;
          flex-shrink: 0;

          svg {
            font-size: 16px;
          }
        }
      }

    }
  }

  .row.filter {
    margin: 0 0  20px 0;
    display: flex;
    align-items: center;

    .filterItem {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      flex-direction: column;

      .form-label {
        font-size: 17px;
        color: #000;
        font-weight: 400;
        white-space: nowrap;
      }

      &>div {
        width: 100%;
      }
    }

    .datePickerItem {
      .form_datepicker{
        height: 35px;
        min-height: 35px;
        border-radius: 20px;
        &:focus {
          box-shadow: 0 0 0 1px #009448;
        }
      }

      .react-datepicker__close-icon::after {
        background-color: #009448;
      }
      .react-datepicker__current-month {
        color: #009448;
        font-weight: 500;
        font-size: 1rem;
        display: none;
      }
      .react-datepicker__month-select,
      .react-datepicker__year-select{
        border-radius: 15px;
        color: #009448;
        border-color: #009448;
        margin: 5px 0;
        font-size: 14px;
      }
      .react-datepicker__navigation-icon--previous::before,
      .react-datepicker__navigation-icon--next::before{
        border-radius: 2px;
      }
      .react-datepicker__navigation-icon {
        top: 3px;
      }
      .react-datepicker__navigation {
        top: 8px;
      }
      .react-datepicker__day--selected,
      .react-datepicker__day--keyboard-selected{
        border-radius: 50%;
        background-color: #009448;
        color: #fff;
      }
      .react-datepicker__day:hover,
      .react-datepicker__month-text:hover,
      .react-datepicker__quarter-text:hover,
      .react-datepicker__year-text:hover {
        border-radius: 50%;
      }
    }

    .pdfButton {
      a {
        background-color: #009448;
        color: #fff;
        height: 35px;
        display: flex;
        border-radius: 30px;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 16px;
        pointer-events: none;
        &:hover {
          filter: brightness(110%);
        }
      }
    }

    @media (min-width: 1024px ) and (max-width: 1164px) {
      .pdfButton a {
        font-size: 12px;
      }
      .filterItem .form-label {
        font-size: 14px;
      }
    }

  }

  .actionsContent {
    .subTitle {
      color: #009448;
      font-size: 18px;
      margin-bottom: 15px;
    }

    .row.actions {
      display: block !important;
      columns: 2;
      column-gap: 15px;
      width: 100%;
      margin: 0 auto;

      .col {
        width: 100%;
        margin: 0 0 15px 0;
        padding: 0;
        break-inside: avoid;
        page-break-inside: avoid;
        display: inline-table;

        .card {
          margin-bottom: 0;
          .card-body {
            padding-left: 40px;
            padding-top: 15px;
            padding-bottom: 5px;
          }
        }
      }

      .goalTitle {
        font-size: 17px;
        font-weight: 500;
        color: #009448;
        margin-bottom: 8px;

        &.noGoal {
          color: #009448;
        }
      }

      .action {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 20px;

        .actionTitle {
          position: relative;
          .icon {
            position: absolute;
            width: 15px;
            height: 15px;
            border: 2px solid;
            top: 5px;
            left: -25px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-color: #009448;

            &:hover {
              background-color: #009448;
              svg {
                display: block !important;
              }
            }

            svg {
              font-size: 12px;
              transform: rotate(352deg);
              color: #fff;
              display: none;
            }
          }
        }
      }

      .info {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;

        &>div {
          background-color: #009448;
          border-radius: 15px;
          padding: 2px 10px;
          color: #fff;
          font-size: 12px;
          pointer-events: none;
        }

        .users {
          pointer-events: all;
          cursor: pointer;
        }

        .actionType,
        .edit {
          width: 22px;
          height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .edit {
          cursor: pointer;
          pointer-events: all !important;
        }
        img {
          width: 14px;
          filter: brightness(0) invert(1) !important;
        }
        svg {
          font-size: 14px;
        }
      }

      @media (max-width: 750px) {
        & {
          columns: 1;
        }
      }
    }

    .actionWithGoals {
      margin-bottom: 20px;
    }

    .actionWithoutGoals {
      margin-bottom: 20px;

      .connectToGoal {
        color: #009448;
        cursor: pointer;
        margin-top: 5px;
      }

      .actionTitle {
        .icon {
          border-color: #009448;

          &:hover {
            background-color: #009448;
          }

        }
      }
    }

    .completedActionGoals {
      margin-top: 40px;

      .connectToGoal {
        color: #009448;
        cursor: pointer;
        margin-top: 5px;
      }

      .actionTitle {
        .icon {
          border-color: #009448;

          &:hover,
          &.active{
            background-color: #009448;

            svg {
              display: block !important;
            }
          }
          &.noGoal {
            background-color: #009448;
            border-color: #009448;
          }
        }
      }
    }

    .expiredActions {
      margin-bottom: 20px;
      .card {
        margin-bottom: 0;
        .card-body {
          padding-left: 40px;
          padding-top: 15px;
          padding-bottom: 5px;
        }
      }
      .action {
        display: flex;
        flex-direction: row;
        position: relative;
      }
      .icon {
        position: absolute;
        width: 15px;
        height: 15px;
        border: 2px solid;
        top: 5px;
        left: -25px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-color: #009448;

        &:hover {
          background-color: #009448;
          svg {
            display: block !important;
          }
        }

        svg {
          font-size: 12px;
          transform: rotate(352deg);
          color: #fff;
          display: none;
        }
      }

      .edit {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #009448;
        border-radius: 15px;
        padding: 0;
        color: #fff;
        font-size: 12px;
        cursor: pointer;
        margin-left: 5px;
        flex-shrink: 0;
        svg {
          font-size: 12px;
        }
      }

      .date {
        font-size: 12px;
      }


    }

    .actionsNotExist {
      text-align: left;
      font-size: 16px;
      padding-left: 15px;
    }

    .action {
      &[data-green-step-id='0'] {
        .icon {
          border-color: #626262 !important;
          &:hover,
          &.active{
            background-color: #626262 !important;
          }
        }
        .edit,
        .info>div {
          background-color: #626262 !important;
        }
        .connectToGoal {
          color: #626262 !important;
        }
      }

      &[data-green-step-id='1'] {
        .icon {
          border-color: #4FC878 !important;
          &:hover,
          &.active{
            background-color: #4FC878 !important;
          }
        }
        .edit,
        .info>div {
          background-color: #4FC878 !important;
        }
        .connectToGoal {
          color: #4FC878 !important;
        }
      }

      &[data-green-step-id='2'] {
        .icon {
          border-color: #FD974F !important;
          &:hover,
          &.active{
            background-color: #FD974F !important;
          }
        }
        .edit,
        .info>div {
          background-color: #FD974F !important;
        }
        .connectToGoal {
          color: #FD974F !important;
        }
      }

      &[data-green-step-id='3'] {
        .icon {
          border-color: #4433CD !important;
          &:hover,
          &.active{
            background-color: #4433CD !important;
          }
        }
        .edit,
        .info>div  {
          background-color: #4433CD !important;
        }
        .connectToGoal {
          color: #4433CD !important;
        }
      }


    }
  }

  .actionsCalendar {

    .fc-view-harness.fc-view-harness-active {
      border-radius: 10px;
      background-color: #F6F6F6;
    }

    .fc-event, .fc-event-dot {
      background-color: transparent !important;
      padding: 0;
    }

    .moreEvents {
      color: #FD974F;
      font-size: 14px;
    }

    .fc {
      td {
        border: 1px solid #FD974F;
        height: 150px;
      }
      th {
        padding: 5px 0;
      }
      th a {
        color: #FD974F;
        font-size: 20px;
      }
      .fc-day-other .fc-daygrid-day-top {
        opacity: 0.3;
        a:not([href]) {
          color: #000;
          font-size: 16px;
        }
      }
      .fc-daygrid-day-top a:not([href]){
        color: #FD974F;
        font-weight: 500;
        font-size: 16px;
      }
    }

    .fc-toolbar h2 {
      font-size: 18px;
      line-height: 1;
      text-transform: capitalize;
      position: relative;
      float: left;
      color: #FD974F;
      height: 52px;
      display: flex;
      align-items: center;
      padding: 0 15px 0 20px;
      justify-content: center;
      text-align: center;
    }

    .fc-header-toolbar button{
      width: 30px !important;
      height: 30px !important;
      opacity: 1;
      position: relative;
      float: left;
      background-color: transparent !important;
      border: none !important;

      span {
        margin-top: 10px !important;
      }

      .fa{
        width: 30px;
        height: 30px;
        background-image: none !important;
        position: relative;
        display: block;

        &:before,
        &:after {
          content:"";
          position: absolute;
          background: #FD974F;
          border-radius: 5px;
          display: block;

        }
      }


      &.fc-prev-button,
      &.fc-next-button {
        .fa{
          &:before {
            top: 0%;
          }
          &:after {
            bottom: 45%;
          }
          &:before,
          &:after {
            left: 0;
            height: 4px;
            width: 20px;
          }
        }
      }

      &.fc-prev-button {
        .fa {
          &:before,
          &:after {
            transform: rotate(-45deg);
          }

          &:after {
            transform: rotate(45deg);
          }
        }
      }
      &.fc-next-button {
        .fa {
          &:before,
          &:after {
            transform: rotate(45deg);
          }

          &:after {
            transform: rotate(-45deg);
          }
        }
      }

    }

    .actionRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
      color: #fff;
      overflow: hidden;
      border-radius: 20px;
      padding: 2px 10px;

      &.high {
        background-color: #F56600;
      }
      &.medium {
        background-color: #FD974F;
      }
      &.low {
        background-color: #FFC79F;
      }

      p {
        margin-bottom: 0;
      }

      .user {
        background-color: #fff;
        border-radius: 50%;
        color: #F56600;
        font-size: 10px;
        width: 22px;
        height: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1px 2px 0;
      }

      .actionType{
        background-color: #fff;
        border-radius: 50%;
        color: #F56600;
        font-size: 10px;
        width: 24px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1px 2px 0;
        flex-shrink: 0;

        img {
          height: 16px;
          padding: 2px;
        }

        img.plus {
          width: 15px;
          height: auto;
        }
      }
    }

  }



}


.createNewGoal {
  .modal-content {
    background-color: #F3F3F3;
    border-radius: 15px;
    padding: 20px;
    border: 1px solid #6f6d6d;
  }

  .modal-header {
    padding-bottom: 0;
    .modal-title {
      color: #009448;
      font-weight: 500;
      font-size: 20px;
      width: 100%;
      text-align: center;
    }
    svg {
      color: #009448;
      position: absolute;
      cursor: pointer;
      top: 10px;
      right: 10px;
    }
  }

  .modal-footer {
    justify-content: flex-start !important;
    padding-top: 0;

    button {
      border-radius: 15px;
      padding: 2px 30px;
      font-size: 16px;
      background: #009448;
    }
  }

  .modal-body {
    .form-label {
      font-size: 14px !important;
      color: #009448;
      padding: 2px 4px;
    }
    .form_element {
      & > input {
        height: 30px;
        min-height: 30px;
        border-radius: 20px;
        &:focus {
          box-shadow: 0 0 0 1px #009448;
        }
      }
    }

  }
}

.deleteActionGoalModal {
  .modal-content {
    background-color: #F3F3F3;
    border-radius: 15px;
    padding: 20px;
    border: 1px solid #6f6d6d;
  }

  .modal-close-icon {
    color: #009448;
    position: absolute;
    cursor: pointer;
    top: -10px;
    right: -10px;
  }

  .title {
    color: #009448;
    font-size: 20px;
    padding-left: 13px;
    padding-bottom: 10px;
  }
  .subTitle {
    color: #009448;
    padding-left: 13px;
  }

  .modal-footer {
    justify-content: flex-start !important;
    padding-top: 0;

    button {
      border-radius: 15px;
      padding: 2px 30px;
      font-size: 16px;
    }
  }
}

.addActionPlanModal {
  max-width: 650px !important;

  .modal-content {
    background-color: #F3F3F3;
    border-radius: 15px;
    padding: 20px 20px 20px 30px;
    border: 1px solid #6f6d6d;
  }

  .modal-header {
    padding-bottom: 0;
    .modal-title {
      color: #009448;
      font-weight: 500;
      font-size: 20px;
    }
    svg {
      color: #009448;
      cursor: pointer;
    }
  }

  .modal-footer {
    justify-content: flex-start !important;
    padding-top: 0;

    button {
      border-radius: 15px;
      padding: 2px 30px;
      font-size: 16px;
    }
  }

  .modal-body {
    .form_row_item {
      margin-bottom: 20px;
      .form_label {
        font-weight: 400;
        font-size: 16px;
        margin: 0;
        padding: 0;
        height: 30px;
        line-height: 30px;

        &.comment_label {
          margin-bottom: 10px;
        }
        &.user_creatable_select {
          height: 40px;
          line-height: 40px;
        }
        .editActionTitle {
          font-weight: 700;
          font-size: 16px;
        }
      }
      .form_element {
        & > input,
        .form_datepicker{
          height: 38px;
          min-height: 38px;
          border-radius: 20px;
          &:focus {
            box-shadow: 0 0 0 1px #009448;
          }
        }
        .editActionTitle {
          font-weight: 500;
          font-size: 16px;
        }
      }
    }

    .secondActionTitle {
      font-size: 14px;
      font-weight: 400;
      opacity: 0.8;
    }

    .add_new_goal {
      position: relative;
      float: left;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin: 8px 5px 0 0;
      cursor: pointer;
      border: 2px solid #009448;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      background-color: #009448;

      &.edit svg{
        font-size: 12px;
      }

      & svg {
        font-size: 15px;
        margin-top: 1px;
        margin-left: 0;
        color: #fff;
        display: block !important;
      }
    }

    .react-datepicker__current-month {
      color: #009448;
      font-weight: 500;
      font-size: 1rem;
    }
    .react-datepicker__navigation-icon--previous::before,
    .react-datepicker__navigation-icon--next::before{
      border-radius: 2px;
    }
    .react-datepicker__navigation {
      top: 8px;
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected{
      border-radius: 50%;
      background-color: #009448;
      color: #fff;
    }
    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover {
      border-radius: 50%;
    }
  }

}