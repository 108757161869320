//
// _badge.scss
//

.rounded-pill {
  padding-left: 0.6em;
  padding-right: 0.6em; /*rtl:left*/
}

// Soft badge
@mixin badge-variant-soft($bg) {
  color: $bg;
  background-color: rgba($bg, 0.18);

  // &[href] {
  //   @include hover-focus {
  //     color: $bg;
  //     text-decoration: none;
  //     background-color: rgba($bg, 0.4);
  //   }
  // }
}

@each $color, $value in $theme-colors {
  .badge-soft-#{$color} {
    @include badge-variant-soft($value);
  }
}


.bg-primary.bg-soft {
  background-color: rgba(85, 110, 230, 0.25) !important; }

.bg-secondary.bg-soft {
  background-color: rgba(116, 120, 141, 0.25) !important; }

.bg-success.bg-soft {
  background-color: rgba(52, 195, 143, 0.25) !important; }

.bg-info.bg-soft {
  background-color: rgba(80, 165, 241, 0.25) !important; }

.bg-warning.bg-soft {
  background-color: rgba(241, 180, 76, 0.25) !important; }

.bg-danger.bg-soft {
  background-color: rgba(244, 106, 106, 0.25) !important; }

.bg-pink.bg-soft {
  background-color: rgba(232, 62, 140, 0.25) !important; }

.bg-light.bg-soft {
  background-color: rgba(239, 242, 247, 0.25) !important; }

.bg-dark.bg-soft {
  background-color: rgba(52, 58, 64, 0.25) !important; }

.badge[href]:hover, .badge[href]:focus {
  color: #fff; }

.badge.bg-primary[href]:hover, .badge.bg-primary[href]:focus {
  background-color: #435fe3 !important; }

.badge.bg-light {
  color: #495057; }
.badge.bg-light[href]:hover, .badge.bg-light[href]:focus {
  color: #495057; }

.badge-soft-primary {
  color: #556ee6;
  background-color: rgba(85, 110, 230, 0.18); }
.badge-soft-primary[href]:hover, .badge-soft-primary[href]:focus {
  color: #556ee6;
  text-decoration: none;
  background-color: rgba(85, 110, 230, 0.4); }

.badge.bg-secondary[href]:hover, .badge.bg-secondary[href]:focus {
  background-color: #6b6e82 !important; }

.badge.bg-light {
  color: #495057; }
.badge.bg-light[href]:hover, .badge.bg-light[href]:focus {
  color: #495057; }

.badge-soft-secondary {
  color: #74788d;
  background-color: rgba(116, 120, 141, 0.18); }
.badge-soft-secondary[href]:hover, .badge-soft-secondary[href]:focus {
  color: #74788d;
  text-decoration: none;
  background-color: rgba(116, 120, 141, 0.4); }

.badge.bg-success[href]:hover, .badge.bg-success[href]:focus {
  background-color: #30b383 !important; }

.badge.bg-light {
  color: #495057; }
.badge.bg-light[href]:hover, .badge.bg-light[href]:focus {
  color: #495057; }

.badge-soft-success {
  color: #34c38f;
  background-color: rgba(52, 195, 143, 0.18); }
.badge-soft-success[href]:hover, .badge-soft-success[href]:focus {
  color: #34c38f;
  text-decoration: none;
  background-color: rgba(52, 195, 143, 0.4); }

.badge.bg-info[href]:hover, .badge.bg-info[href]:focus {
  background-color: #3d9bef !important; }

.badge.bg-light {
  color: #495057; }
.badge.bg-light[href]:hover, .badge.bg-light[href]:focus {
  color: #495057; }

.badge-soft-info {
  color: #50a5f1;
  background-color: rgba(80, 165, 241, 0.18); }
.badge-soft-info[href]:hover, .badge-soft-info[href]:focus {
  color: #50a5f1;
  text-decoration: none;
  background-color: rgba(80, 165, 241, 0.4); }

.badge.bg-warning[href]:hover, .badge.bg-warning[href]:focus {
  background-color: #f0ac39 !important; }

.badge.bg-light {
  color: #495057; }
.badge.bg-light[href]:hover, .badge.bg-light[href]:focus {
  color: #495057; }

.badge-soft-warning {
  color: #f1b44c;
  background-color: rgba(241, 180, 76, 0.18); }
.badge-soft-warning[href]:hover, .badge-soft-warning[href]:focus {
  color: #f1b44c;
  text-decoration: none;
  background-color: rgba(241, 180, 76, 0.4); }

.badge.bg-danger[href]:hover, .badge.bg-danger[href]:focus {
  background-color: #f35757 !important; }

.badge.bg-light {
  color: #495057; }
.badge.bg-light[href]:hover, .badge.bg-light[href]:focus {
  color: #495057; }

.badge-soft-danger {
  color: #f46a6a;
  background-color: rgba(244, 106, 106, 0.18); }
.badge-soft-danger[href]:hover, .badge-soft-danger[href]:focus {
  color: #f46a6a;
  text-decoration: none;
  background-color: rgba(244, 106, 106, 0.4); }

.badge-soft-pink {
  color: #e62c81;
  background-color: rgba(232, 62, 140, 0.18); }
.badge-soft-danger[href]:hover, .badge-soft-danger[href]:focus {
  color: #e62c81;
  text-decoration: none;
  background-color: rgba(232, 62, 140, 0.18); }

.badge.bg-pink[href]:hover, .badge.bg-pink[href]:focus {
  background-color: #e62c81 !important; }

.badge.bg-light {
  color: #495057; }
.badge.bg-light[href]:hover, .badge.bg-light[href]:focus {
  color: #495057; }

.badge-soft-pink {
  color: #e83e8c;
  background-color: rgba(232, 62, 140, 0.18); }
.badge-soft-pink[href]:hover, .badge-soft-pink[href]:focus {
  color: #e83e8c;
  text-decoration: none;
  background-color: rgba(232, 62, 140, 0.4); }

.badge.bg-light[href]:hover, .badge.bg-light[href]:focus {
  background-color: #e1e7f0 !important; }

.badge.bg-light {
  color: #495057; }
.badge.bg-light[href]:hover, .badge.bg-light[href]:focus {
  color: #495057; }

.badge-soft-light {
  color: #eff2f7;
  background-color: rgba(239, 242, 247, 0.18); }
.badge-soft-light[href]:hover, .badge-soft-light[href]:focus {
  color: #eff2f7;
  text-decoration: none;
  background-color: rgba(239, 242, 247, 0.4); }

.badge.bg-dark[href]:hover, .badge.bg-dark[href]:focus {
  background-color: #2b3035 !important; }

.badge.bg-light {
  color: #495057; }
.badge.bg-light[href]:hover, .badge.bg-light[href]:focus {
  color: #495057; }

.badge-soft-dark {
  color: #343a40;
  background-color: rgba(52, 58, 64, 0.18); }
.badge-soft-dark[href]:hover, .badge-soft-dark[href]:focus {
  color: #343a40;
  text-decoration: none;
  background-color: rgba(52, 58, 64, 0.4); }

.rounded-pill {
  padding-left: .6em;
  padding-right: .6em; }

.badge.bg-dark {
  color: #eff2f7; }

