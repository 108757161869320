.dashboard_page {

  .cardTitle {
    color: #009448;
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .card {
     width: 100%;
   }

  .dashboardTypes {
    display: flex;
    flex-direction: row;
    gap: 20px;
    font-size: 18px;
    margin: 20px 0;

    .type {
      min-width: 180px;
      text-align: center;
      position: relative;
      cursor: pointer;
      height: 35px;
    }

    .active {
      color: #009448;
      font-weight: 500;

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: #009448;
      }
    }
  }

  .dashboard_company {
    .greenStepsCircle {

      .card-body {
        padding-bottom: 2rem;
      }

      .category {
        text-align: center;
        font-weight: 500;
        font-size: 16px;

        &.categoryItem_1 {
          color: #4FC878;
        }
        &.categoryItem_2 {
          color: #FD974F;
        }
        &.categoryItem_3 {
          color: #4433CD;
        }
      }
    }

    .actionPlanCard {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      height: auto;

      .emptyText {
        text-align: center;
        color: #009448;
        min-height: 160px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: 500;
      }

      .card-body {
        display: flex;
        width: 100%;
        padding-left: 1.5rem;
      }

      .actions {
        color: #009448;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 16px;
        gap: 10px;

        .action {
          display: flex;
          gap: 10px;

          span {
            color: #000;
          }
        }

        .categoryItem_1 {
          color: #4FC878;
        }
        .categoryItem_2 {
          color: #FD974F;
        }
        .categoryItem_3 {
          color: #4433CD;
        }
      }
    }

    .greenStepsChart {
      .card-title {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin: 30px 30px 0;
        color: #009448;
        .title {
          font-weight: 500;
          font-size: 18px;
        }
        .buttons {
          display: flex;
          flex-wrap: nowrap;
          gap: 0.5em;
          justify-content: flex-end;

          .customBtn {
            border: 1px solid #009448;
            border-radius: 30px;
            font-size: 14px;
            cursor: pointer;
            padding: 0 1em;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;

            &.active {
              background-color: #009448;
              color: #fff;
            }
          }
        }
      }

      .card-body.single {
        height: 300px;
      }

      .card-body.multy {
        height: 350px;
      }

      .card-body.empty {
        padding: 2rem;
       .title {
         font-weight: 500;
         color: #009448;
         margin-bottom: 10px;
         font-size: 16px;
         width: 80%;
       }
      .carbonBtns {
        .btn {
          background-color: #4FC878;
          color: #fff;
          border-radius: 20px;
          height: 30px;
          line-height: 1;
          width: auto;
          margin: 5px 0;
        }
      }
      }

      .apexcharts-yaxis-label tspan,
      .apexcharts-xaxis-label tspan{
        fill: #009448;
      }
      .apexcharts-gridline {
        pointer-events: none;
        stroke: #009448;
        opacity: 0.4;
      }

      @media (max-width: 1200px) {
        .card-title {
          flex-direction: column;
          gap: 0.5em;
          .buttons {
            flex-wrap: wrap;
          }
        }
      }
    }

    .greenStepPolicies {
      .card-body {
        padding-right: 2rem;
      }

      @media (max-width: 500px) {
        .card-body {
          column-count: 1 !important;
        }
      }

      .card-body {
        display: table-column;
        column-count: 1;
        width: 100%;
      }

      .card-body.empty {
        column-count: 1;
      }

      .emptyText {
        text-align: center;
        color: #009448;
        min-height: 160px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: 500;
      }

      .categoryItem {
        border: 0.5px solid;
        border-radius: 15px;
        margin: 10px 0px 20px 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }

      .item {
        padding: 12px 10px 10px 18px;
        display: inline-block;
        width: 50%;
      }

      .openGreenStep {
        cursor: pointer;
      }


      .title {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
      }

      .pdfUploaderContainer {
        width: 100%;
        margin-bottom: 0;
        border: none;
        padding: 0;

        .pdf_label {
          text-align: left;
          font-weight: 400;
        }
      }

      .row {
        width: 100%;
        position: relative;
        float: left;
        --bs-gutter-x: 0;

        .inputCol {
          width: 83% !important;
        }
        div {
          padding: 0;
        }
      }
      .pdfContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 20px 0 5px 0;

        a {
          display: flex;
          align-items: center;
          width: 75%;
        }
        .pdf_name {
          margin: 0 0px 0 10px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        img {
          height: 30px;
        }
        svg {
          transform: none !important;
        }
        .pdfLink {
          margin-right: 20px;
          display: flex;
          flex-direction: column;
          gap: 1px;
          word-break: break-word;
        }
      }

      .pdfContainerfromLink {
        margin: 10px 0 0 0;
        position: relative;
        display: block;
        min-height: 65px;
        label {
          position: relative;
          float: left;
          width: 100%;
          font-size: 15px;
          font-weight: normal;
          margin-bottom: 5px;
        }
        input {
          position: relative;
          float: left;
          border-radius: 15px;
          height: 25px;
          padding: 5px;
          font-size: 12px;
        }
        .companyCompleted {
          height: 25px;
          position: relative;
          float: left;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
        }
      }

      .companyCompletedIcon {
        position: relative;
        float: left;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 4px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
        flex-shrink: 0;

        &:hover svg,
        &.true svg {
          display: block !important;
        }
        &.edit svg {
          font-size: 12px !important;
        }
        svg {
          font-size: 14px;
          transform: rotate(352deg);
          color: #fff;
          display: none;
        }
      }

      .completeBtn {
        width: 100px;
        border-radius: 10px;
        color: #fff;
        text-align: center;
        cursor: pointer;
      }

      .categoryItem[data-category='0'] {
        .categoryTextColor,
        .pdfUploaderContainer label {
          color: #4FC878 !important;
        }
        .categoryBgColor,
        .companyCompletedIcon.true{
          background-color: #4FC878 !important;
        }

        &,
        .pdfContainerfromLink input {
          border-color: #4FC878 !important;
        }
      }

      .categoryItem[data-category='1'] {
        .categoryTextColor,
        .pdfUploaderContainer label {
          color: #FD974F !important;
        }
        .categoryBgColor,
        .companyCompletedIcon.true{
          background-color: #FD974F !important;
        }

        &,
        .pdfContainerfromLink input {
          border-color: #FD974F !important;
        }

      }

      .categoryItem[data-category='2'] {
        .categoryTextColor,
        .pdfUploaderContainer label {
          color: #4433CD !important;
        }
        .categoryBgColor,
        .companyCompletedIcon.true{
          background-color: #4433CD !important;
        }

        &,
        .pdfContainerfromLink input {
          border-color: #4433CD !important;
        }
      }
    }

    .storiesCard {
      height: 400px;

      .foot {
        color: #009448;
        position: absolute;
        bottom: 10px;
        right: 10px;
        cursor: pointer;
        text-decoration: underline;
      }

      .emptyText {
        text-align: center;
        color: #009448;
        padding: 30px 0;
        height: 400px;
        display: flex;
        align-items: center;
      }

      .edit {
        position: absolute;
        top: 26px;
        right: 0;
        cursor: pointer;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        background-color: #009448;
        padding: 0px 5px;
        border-radius: 5px;
        display: none;
      }

      .slick-slide.slick-active .edit{
        display: block;
      }

      .slick-track {
        overflow: hidden;
      }

      .slick-slide {
        padding: 0;
        height: 400px;

        & > div {
          margin-top: 15%;
          background-color: #D9D9D9;
          height: 70%;
          border-radius: 10px;
          overflow: hidden;
        }
      }

      .slick-slide {
        transform: perspective(600px) rotateY(20deg) scale(0.9);
        transition: all ease-in-out 0.5s;
      }

      .slick-active {
        transform: perspective(600px) rotateY(0deg) scale(1);
      }

      .slick-active ~ .slick-slide {
        transform: perspective(600px) rotateY(-20deg) scale(0.9);
      }

      .slick-dots {
        bottom: 20px;

        li button:before {
          font-size: 10px;
          color: #009448;
        }

      }

      .slick-arrow {
        width: 100px;
        height: 60%;
        padding: 0;
        z-index: 9;
        background-color: transparent !important;
        &:before {
          display: none;
        }
      }

      .slick-arrow.slick-next {
        right: 0;
      }
      .slick-arrow.slick-prev {
        left: 0;
      }
    }

    .tips {
      min-height: 390px;

      .card-body {
        border-radius: 12px;
        display: flex;
        align-items: center;

        &[data-category='1'] {
          background-color: #4fc878;
        }
        &[data-category='2'] {
          background-color: #FD974F;
        }
      }

      .carousel {
        width: 100%;
        text-align: left;
        display: flex;
        flex-direction: row;
        align-items: center;

        .carousel-inner {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          flex-grow: 1;
          width: 100%;
        }
        .carousel-item {
          height: 100%;
          width: 100%;
          flex-direction: row;
          justify-content: center;
          gap: 10px;
          align-items: center;
          transition: all 0.5s ease;
          color: #fff;
          font-size: 16px;
          font-weight: 500;
        }
        .copy {
          width: 80%;
        }

        .carousel-item.active, .carousel-item-next, .carousel-item-prev {
          display: flex !important;
        }

        .carousel-control-prev {
          justify-content: flex-start;
        }
        .carousel-control-next {
          justify-content: flex-end;
          right: -5px;
        }

        .carousel-control {
          width: 5% !important;
          opacity: 1;
          position: relative;

          &.false {
            pointer-events: none;
            span {
              opacity: 0.2;
            }
          }

          span {
            margin-top: 10px !important;
          }
          .carousel-control-prev-icon,
          .carousel-control-next-icon{
            width: 30px;
            height: 30px;
            background-image: none !important;
            position: relative;

            &:before,
            &:after {
              content:"";
              position: absolute;
              background: #fff;
              border-radius: 5px;
              display: block;
            }
          }


          &.left,
          &.right {
            .carousel-control-prev-icon,
            .carousel-control-next-icon{
              &:before {
                top: 0%;
              }
              &:after {
                bottom: 48%;
              }
              &:before,
              &:after {
                left: 0;
                height: 4px;
                width: 20px;
              }
            }
          }

          &.left {
            .carousel-control-prev-icon,
            .carousel-control-next-icon {
              &:before,
              &:after {
                transform: rotate(-45deg);
              }

              &:after {
                transform: rotate(45deg);
              }
            }
          }
          &.right {
            .carousel-control-prev-icon,
            .carousel-control-next-icon {
              &:before,
              &:after {
                transform: rotate(45deg);
              }

              &:after {
                transform: rotate(-45deg);
              }
            }
          }

        }
      }
    }

    .widgetCard {
      cursor: pointer;
      width: 300px;

      .card-body{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .foot {
        color: #009448;
        position: absolute;
        bottom: 10px;
        right: 10px;
        text-decoration: underline;
      }
      .widget {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 130px;
      }

      .logo {
        width: 80%;
        height: 30px;
        padding: 0 !important;
        background-size: contain;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        cursor: pointer;
      }

      .leaves {
        margin: 10px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        z-index: 9;
      }

      .leaf-icon,
      .leaf_item{
        color: #fff;
        height: 40px;
        width: 45px;
        margin-right: 4px;
        cursor: pointer;
      }

    }

  }

  .dashboard_municipality {
    .firstRow {
      .top3 {
        .card-body {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          gap: 10px;
          min-height: 180px;
        }

        .item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 20px;
        }

        .title {
          font-size: 16px;
          font-weight: 500;
          color: #000;
          text-align: center;
        }

        .leaves {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          z-index: 9;
          gap: 2px;

          .leaf_item {
            color: #fff;
            height: 35px;
            width: 35px;
            cursor: pointer;

            .leaf-icon {
              color: #fff;
              height: 35px;
              width: 35px;
            }
          }
        }
      }

      .municipalityCount {
        .card-body {
          display: flex;
          align-items: center;
          justify-content: space-around;
          gap: 10px;
          min-height: 180px;
          padding: var(--bs-card-spacer-y) 20px var(--bs-card-spacer-y) 0;
        }
        .chart {
          width: 145px;
          flex-shrink: 0;
        }
        .item {
          display: flex;
          flex-direction: column;
          gap: 20px;

          .title {
            font-size: 16px;
            font-weight: 500;
            color: #000;
          }
          .count {
            font-size: 26px;
            font-weight: 500;

            &.first {
              color: #818282;
            }
            &.second {
              color: #009448;
            }
            &.third {
              color: #005725;
            }
          }
        }
      }
    }

    .secondRow {
      .greenStepsChart {
        .card-title {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          margin: 30px 30px 0;
          color: #009448;
          .title {
            font-weight: 500;
            font-size: 18px;
          }
          .buttons {
            display: flex;
            flex-wrap: nowrap;
            gap: 0.5em;
            justify-content: flex-end;

            .customBtn {
              border: 1px solid #009448;
              border-radius: 30px;
              font-size: 14px;
              cursor: pointer;
              padding: 0 1em;
              display: flex;
              align-items: center;
              justify-content: center;
              white-space: nowrap;

              &.active {
                background-color: #009448;
                color: #fff;
              }
            }
          }
        }

        .card-body {
          padding-top: 0;
        }

        .apexcharts-yaxis-label tspan,
        .apexcharts-xaxis-label tspan{
          fill: #009448;
        }
        .apexcharts-gridline {
          pointer-events: none;
          stroke: #009448;
          opacity: 0.4;
        }

        @media (max-width: 1200px) {
          .card-title {
            flex-direction: column;
            gap: 0.5em;
            .buttons {
              flex-wrap: wrap;
            }
          }
        }
      }

      .greenStepsCircle {
        .category {
          text-align: center;
          font-weight: 500;
          font-size: 16px;

          &.categoryItem_1 {
            color: #4FC878;
          }
          &.categoryItem_2 {
            color: #FD974F;
          }
          &.categoryItem_3 {
            color: #4433CD;
          }
        }
      }
    }

    .thirdRow {
      .cardHead {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        color: #009448;

        .cardAddNew {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          font-weight: 500;
          font-size: 16px;
          cursor: pointer;
        }
      }

      .companiesCard {
        .table {
          display: flex;
          flex-direction: column;
          gap: 20px;
          margin: 20px;
        }

        .head {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          color: #009448;
          font-size: 18px;
          font-weight: 500;
          gap: 20px;

          .col {
            font-weight: 500;
            padding: 0.75rem 0.75rem;
          }
          .col.first {
            padding: 0.75rem 1.5rem 0.75rem 0;
          }
        }

        .body {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .row {
            gap: 20px;
          }

          .col {
            color: #000;
          }

          .col.name {
            a {
              color: #000;
            }
            &:hover a {
              color: #009448;
            }
          }

          .leaves {
            display: flex;
            flex-direction: row;
            gap: 2px;

            .leaf_item {
              color: #fff;
              height: 30px;
              width: 30px;
              cursor: pointer;

              .leaf-icon {
                color: #fff;
                height: 30px;
                width: 30px;
              }
            }
          }
          .users {
            display: flex;
            flex-direction: column;
            gap: 5px;
            align-items: flex-start;
            justify-content: center;
          }
        }
      }
    }
  }
}

.dashboardWidgetModal {
  .modal-content {
    background-color: #F3F3F3;
    border-radius: 15px;
    border: 1px solid #6f6d6d;
  }
  .modal-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #009448;
    margin: 30px 50px 0 50px;
    border: none;
    padding: 0;

    .t1 {
      font-weight: bold;
      font-size: 20px;
    }
    svg {
      position: absolute;
      top: 15px;
      right: 15px;
      color: #009448;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
  .modal-body {
    margin: 0 50px 40px 50px;
    border: none;
    background-color: #E7E7E7;
  }

}

.newOrganizationModal {
  .modal-content {
    padding: 20px;
  }
  .modal-header {
    border-bottom: 0;
    font-size: 23px;
    font-weight: 500;
    color: #009448;
  }
  .modal-footer {
    border: none;
    justify-content: flex-end !important;
    button {
      padding: 4px 20px;
      font-size: 16px;
    }
    .closeBtn {
      color: #009448;
      border: 1px solid #009448;
    }
  }
  .modal-body {
    padding-bottom: 0;
    margin: 0 20px;
    .row {
      margin-bottom: 20px;
    }
    input {
      padding: 4px 10px;
      background-color: #F6F6F6;
    }
    .invalid-feedback {
      display: block !important;
    }
  }
}