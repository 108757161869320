.educationPage {
  margin-bottom: 60px;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 20px;

    .title {
      color: #009448;
      font-weight: 500;
      font-size: 22px;
      text-align: center;
    }

    .headerBoxes {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 20px;
      margin: 80px 0 20px 0;
      width: 100%;

      .box {
        background-color: #fff;
        border-radius: 10px;
        height: 140px;
        width: 24%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-size: 18px;
        color: #858585;
        cursor: pointer;
        border: 2px solid #fff;

        .icon {
          background-size: contain;
          background-position: 50% 50%;
          width: 50px;
          height: 50px;
          background-repeat: no-repeat;
          filter: contrast(0);

          &.icon1 {
            background-size: 115% !important;
          }
        }

        &.active,
        &:hover {
          border-color: #009448;
          color: #009448 !important;

          .icon {
            filter: contrast(1);
          }
        }
      }
    }

  }

  .videosContent {
    .row  {
      margin: 40px 0;
    }
    .videoFrame {
      width: 100% !important;
      border-radius: 10px !important;
      overflow: hidden;
      border: 1px solid #878585;
    }
    .desc {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      font-size: 16px;

      .title {
        color: #009448;
        font-weight: 500;
      }
      .copy {

      }
    }
  }

  .faqPage {
    .row {
      justify-content: center;
    }
    .title {
      color: #009448;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 20px;
    }
    .subTitle {
      color: #009448;
      font-size: 16px;
      text-align: center;
      margin-bottom: 40px;
    }
  }

  .accordion {
    .item {
      margin: 20px 0;
    }
    .accordion-button {
      box-shadow: none;
      color: #009448;
      font-size: 18px;
      border-radius: 10px !important;
      background-color: #fff !important;
      padding: 10px 20px;
    }
    .accordion-button::after {
      width: 24px;
      height: 24px;
      background-size: 24px;
    }
    .accordion-body {
      font-size: 16px;

    }
    .accordion-collapse {
      .row {
        justify-content: flex-start;
      }
    }
  }
}