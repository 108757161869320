.carbonAdminPage {

  .accordion {
    margin: 10px 0;
  }

  .accordion-item {
    border-radius: 10px;
  }

  .accordion-button {
    border-radius: 10px;
    cursor: pointer;
  }

  .accordion-body {
    padding: 0px !important;
  }

  .accordion-button::after {
    display: none !important;
  }

}
