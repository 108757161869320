.addAnnouncementModal {
  .row {
    margin: 20px 0;
  }

  .form_empty {
    margin-top: 15px;
  }

  textarea {
    width: 100%;
    border-radius: 5px;
    border-color: #dad7d7;
    padding: 5px 10px;
    resize: none;

    &.error {
      border-color: red;
    }
  }
}

.announcementListPage {
  .containerRow {
    margin-bottom: 40px;

    .logRow {
      margin: 5px 0;
      color: #333333;
      font-size: 16px;
      &.desc {
        margin-left: 50px;
      }
      .categoryTextColor {
        font-weight: 500;
        cursor: pointer;
      }
      &.desc.mainIndex_1 {
        .categoryTextColor {
          color: #4FC878;
        }
      }
      &.desc.mainIndex_2 {
        .categoryTextColor {
          color: #FD974F;
        }
      }
      &.desc.mainIndex_3 {
        .categoryTextColor {
          color: #4433CD;
        }
      }
    }
    .logDate {
      color: rgba(51,51,51,0.5);
      font-weight: 500;
      margin-left: 50px;
    }
    .logType {
      width: 50px;
      height: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      float: left;
      gap: 10px;

      &>svg {
        width: 20px;
        color: #009448;
      }
      .badge {
        display: block;
        width: 10px;
        height: 10px;
        background-color: red;
        cursor: pointer;
      }
    }
    .logCategory {
      color: #000;
      font-weight: 500;
    }
  }
}