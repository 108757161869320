.userProfile {
  .header {
    height: 380px;

    .banner {
      border-radius: 10px;
      background-color: #fff;
      height: 300px;

      .bannerImage {
        height: 300px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        padding: 10px;
        border-radius: 10px;
      }

      .picture-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
    }

    .logo {
      position: absolute;
      top: 230px;
      right: 50px;
      width: 130px;
      height: 150px;
      display: flex;
      flex-direction: column;

      .logoImg {
        background-color: #fff;
        width: 100%;
        height: 130px;
        border: 1px solid #dcdada;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
      }

      .logoImage {
        width: 100%;
        height: 100%;
        margin: 5px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 50%;
      }

      .changeLogo {
        color: #009448;
        text-decoration: underline;
        font-size: 14px;
        cursor: pointer;
      }

      .picture {
        background-color: #fff;
        height: 90px;
      }

      .picture-container .picture:hover {
        border-color: #009448;
      }
    }

    .copy {
      position: absolute;
      top: 350px;
      left: 12px;
      max-width: 80%;
      display: flex;
      gap: 10px;
      font-size: 20px;
      color: #000;

      .companyName {
        text-transform: uppercase;
        font-weight: bold;
      }
    }

    .changeHeaderImage {
      position: absolute;
      top: 310px;
      left: 12px;
      color: #009448;
      text-decoration: underline;
      cursor: pointer;
    }

  }

  .cardTabs {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 20px;
    color: #000;
    margin: 20px 0;

    .tab {
      position: relative;
      padding: 0 10px;
      font-size: 18px;

      &.active,
      &:hover {
        color: #009448;
        cursor: pointer;
        font-weight: 500;
      }
      &.active:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #009448;
        border-radius: 5px;
      }
    }
  }

  .users {

    .card {
      padding: 40px;
    }

    .card-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: #000;
      padding: 0;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 20px;

      .addNew {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;
        color: #009448;

        &.disabled {
          pointer-events: none;
          opacity: 0.5;
        }
      }
    }

    .card-body {
      padding: 0;

      .table {
        display: flex;
        flex-direction: column;

        .head {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          color: #009448;
          font-size: 18px;
          font-weight: 500;
          gap: 20px;
          border-bottom: 1px solid #000;

          .col {
            font-weight: 500;
            padding: 0.75rem 0.75rem;
          }
          .col.first {
            padding: 0.75rem 1.5rem 0.75rem 0;
          }
        }

        .tableBody {
          display: flex;
          flex-direction: column;
          justify-content: center;
          overflow: hidden;

          .row:last-child {
            border-bottom: none;
          }

          .row {
            gap: 20px;
            border-bottom: 1px solid #000;
            height: 60px;
          }

          .col {
            display: flex;
            align-items: center;
            color: #000;
          }

          .notification {
            color: #009448;
          }

          .col.actions {
            justify-content: flex-end;
            flex-direction: row;

            .action {
              cursor: pointer;
              color: #009448;
              text-decoration: underline;
              margin: 0 5px;
            }
          }

          input:not([type="checkbox"]) {
            background-color: #F6F6F6;
          }

        }
      }

    }
  }

  .companies {

    .card {
      padding: 40px;
    }

    .card-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: #000;
      padding: 0;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 20px;

      .addNew {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-weight: 400;
        font-size: 16px;
      }


      .react-custom-switch {
        width: 40px;
        height: 20px;

        &.active {
          .react-switch-handle {
            transform: translateX(20px) !important;
          }
          .react-switch-bg,
          .react-switch-handle{
            background-color: #4FC878 !important;
          }
        }


        .react-switch-bg {
          height: 100% !important;
          width: 100% !important;
          opacity: 0.4;
          background: rgba(141, 134, 130, 0.7);
        }
        .react-switch-bg div {
          display: none !important;
        }
        .react-switch-handle {
          height: 20px !important;
          width: 20px !important;
          top: 0px !important;
          background-color: rgba(116, 105, 97, 0.5) !important;
        }
        .react-switch-handle div {
          height: 20px !important;
          width: 20px !important;
        }
        .react-switch-handle div svg {
          font-size: 14px;
          color: #fff;
        }
      }
    }

    .card-body {
      padding: 0;

      .table {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .head {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          color: #009448;
          font-size: 18px;
          font-weight: 500;
          gap: 20px;
          border-bottom: 1px solid #000;

          .col {
            font-weight: 500;
            padding: 0.75rem 0.75rem;
          }
          .col.first {
            padding: 0.75rem 1.5rem 0.75rem 0;
          }
        }

        .tableBody {
          display: flex;
          flex-direction: column;
          justify-content: center;
          overflow: hidden;

          .row:last-child {
            border-bottom: none;
          }

          .row {
            min-height: 60px;
            gap: 20px;
            border-bottom: 1px solid #000;
          }

          .col {
            color: #000;
            display: flex;
            align-items: center;
            padding: 2rem 0.75rem;
          }

          .col.description textarea{
            width: 100%;
            border-radius: 5px;
            padding: 5px 10px;
            resize: none;
          }

          .col.category {
            width: 200px;

            &>div {
              width: 100%;
            }
          }

          .col.actions {
            justify-content: flex-end;
            flex-direction: row;

            .action {
              cursor: pointer;
              color: #009448;
              text-decoration: underline;
            }

          }
        }
      }
    }

    .categoriesOption {
      display: flex;
      gap: 10px;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  .subscription {
    .card {
      padding: 40px;
      .card-body {
        padding: 0;
      }
    }

    .activePlan {
      .title {
        color: #000;
        margin-bottom: 10px;
      }
      .currentPlanName {
        color: #000;
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 20px;
      }
      .plan {
        color: #000;
      }
      .nextPay {
        margin-top: 20px;
      }
      .chooseSubscription {
        position: absolute;
        top: 40px;
        right: 50px;
        width: 140px;
        height: 35px;
        background-color: #009448;
        color: #fff;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        cursor: pointer;
      }
      .changeOrCancelSubscription {
        position: absolute;
        top: 30px;
        right: 30px;
        width: 200px;
        height: 35px;
        background-color: #009448;
        color: #fff;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        cursor: pointer;
      }
      .unSubscribe {
        position: absolute;
        top: 90px;
        right: 50px;
        width: 140px;
        height: 35px;
        background-color: #fff;
        border: 1px solid #009448;
        color: #009448;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        cursor: pointer;
      }

    }

    .history {
      .title {
        color: #000;
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 20px;
      }

      .table {
        display: flex;
        flex-direction: column;

        .head {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          font-size: 16px;
          text-transform: uppercase;
          gap: 20px;

          .col {
            color: #939393;
            padding: 0.75rem 0.75rem;
          }
          .col.first {
            padding: 0.75rem 1.5rem 0.75rem 0;
          }
        }

        .tableBody {
          display: flex;
          flex-direction: column;
          justify-content: center;
          overflow: hidden;

          .row {
            gap: 20px;
          }

          .col {
            color: #000;
            display: flex;
            align-items: center;
            padding-top: 4px;
            padding-bottom: 4px;
          }
        }
      }





    }
  }

  .stories {
    .card {
      transition: transform 0.5s;
      transform-style: preserve-3d;

      &.active {
        transform: rotateY( 180deg );

        .cardBack{
          visibility: visible;
          opacity: 1;
          height: 100%;
        }

        .cardFront{
          visibility: hidden;
          opacity: 0;
          height: 0;
        }
      }

      .cardFront {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        visibility: visible;
        opacity: 1;
        height: 100%;
      }

      .cardBack {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        transform: rotateY(180deg) !important;
        visibility: hidden;
        opacity: 0;
        height: 0;
      }
    }

    .storiesHeader {
      color: #009448;
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      margin-bottom: 20px;
    }

    .picture-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0;
      gap: 20px;

      .picture  {
        width: 80px;
        height: 70px;
        margin: 0;
      }
    }

    .videoBtn {
      padding: 5px 20px;
      align-self: end;
      white-space: nowrap;
    }

    .videoTitle {
      font-weight: 500;
      font-size: 16px;
      color: #009448;
      text-align: center;
      margin-top: 20px;
    }

    .videoStatus {
      color: #fff;
      position: absolute;
      top: 0;
      right: 0;
      padding: 5px 10px;
      border-bottom-left-radius: 12px;

      &.approved {
        background-color: #009448;
      }

      &.rejected {
        background-color: #ff715b;
      }

      &.waiting {
        background-color: #eeb902;
      }

    }

    .videoPlayer {
      width: 100% !important;
      height: 100% !important;
      aspect-ratio: 16 / 9;
    }

    button.actionButton {
      border-radius: 0.8rem;
      padding: 5px 20px;
    }

    .footerBtn {
      display: flex;
      gap: 20px;
      justify-content: flex-end;
    }

    .player__play-icon {
      border-color: transparent transparent transparent #009369 !important;
    }

    .react-player__preview {
      background-size: contain !important;
      background-repeat: no-repeat !important;
    }

  }

}

.chooseSubscriptionModal {
  .modal-header {
    border-bottom: 0;
    color: #009448;
    padding: 30px 0 10px 0;

    .modal-title {
      font-size: 20px;
      font-weight: bold;
    }
    svg {
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 18px;
      cursor: pointer;
    }
  }

  .modal-body {
    padding: 0;
  }

  .modal-footer {
    border: none;
    margin-top: 20px;
    margin-bottom: 10px;
    justify-content: flex-end !important;

    button {
      padding: 8px 20px;
    }
    .cancelBtn {
      background-color: #fff;
      color: #009448;
      border-width: 2px;
    }
  }

  .plan {
    padding-top: 20px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;

    .planHead {
      position: relative;
      float: left;
      width: 150px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      color: #009448;
      border-radius: 15px;
      font-size: 18px;
      gap: 20px;
      padding: 28px 0 0 0;
      margin: 0 10px 0 0;
      user-select: none;
    }

    .planCol {
      position: relative;
      float: left;
      width: 180px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      transition: all 0.5s ease-out;

      .planName {
        color: #009448;
        font-size: 18px;
        text-align: center;
        font-weight: 500;
      }

      .planItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 15px;
        font-size: 18px;
        gap: 20px;
        padding: 20px 0 40px 0;
        margin: 0 10px;
        cursor: pointer;
        transition: all 0.2s;
        user-select: none;

        svg {
          transform: rotate(352deg);
          font-size: 20px;
        }
      }

      &:hover {
        transform: scale(1.03);
      }

      &.selected {
        transform: scale(1.1);
      }

      &.planCol_0 {
        .planItem {
          border: 2px solid #44DC8E;
          color: #44DC8E;
        }
        .planName { color:  #44DC8E; }
        .item.meeting svg,
        .item.personal svg{
          visibility: hidden;
        }
      }
      &.planCol_1 {
        .planItem {
          border: 2px solid #2CA869;
          color: #2CA869;
        }
        .planName { color:  #2CA869; }
        .item.personal svg{
          visibility: hidden;
        }
      }
      &.planCol_2 {
        .planItem {
          border: 2px solid #009448;
          color: #009448;
        }
        .planName { color:  #009448; }
      }


      &.disabled {
        pointer-events: none;
        opacity: 0.8;
        transform: scale(1);
        &:hover {
          transform: scale(1);
        }
      }

      &.planCol_0.selected {
        background-color: #44DC8E;
        color: #fff;
      }
      &.planCol_1.selected {
        background-color: #2CA869;
        color: #fff;
      }
      &.planCol_2.selected {
        background-color: #009448;
        color: #fff;
      }
    }

  }

  .confirmContainer {
    margin: 40px 0 20px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    color: #000;
    font-size: 16px;

    .currentPlan,
    .newPlan{
      background-color: #F6F6F6;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .copy {
      width: 80%;
    }

    > div {
      padding-left: 10%;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

.unSubscribeModal {
  .modal-header {
    border-bottom: 0;
    color: #009448;
    padding: 30px 0 10px 0;

    .modal-title {
      font-size: 20px;
      font-weight: bold;
    }
    svg {
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 18px;
      cursor: pointer;
    }
  }

  .modal-body {
    .title {
      text-align: center;
      color: #009448;
      font-size: 20px;
      font-weight: 500;
    }
  }

  .modal-footer {
    border: none;
    margin-top: 20px;
    margin-bottom: 10px;
    justify-content: flex-end !important;

    button {
      padding: 8px 20px;
    }
    .cancelBtn {
      background-color: #fff;
      color: #009448;
      border-width: 2px;
    }
  }
}

.swal-modal.deleteUserModal {
  width: 400px;
  padding: 40px 10px 20px 40px;
  border-radius: 15px;

  .swal-title {
    margin: 0;
    color: #000;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    padding: 0;
  }
  .swal-text {
    border: 1px solid;
    float: left;
    margin: 20px 0;
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    color: #000;
    font-size: 14px;
  }
  .swal-footer {
    float: right;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row-reverse;

    .swal-button--cancel {
      background-color: #fff !important;
      color: #717171;
      padding: 0 5px;
      font-size: 16px;
      font-weight: 500;
      &:focus {
        box-shadow: none !important;
      }
    }
    .swal-button--confirm{
      background-color: #fff !important;
      color: #009448;
      padding: 0 5px;
      font-size: 16px;
      font-weight: 500;
      &:focus {
        box-shadow: none !important;
      }
    }
  }

}

.addNewCompanyUserModal {
  .modal-content {
    padding: 20px;
  }
  .modal-header {
    border-bottom: 0;
    font-size: 23px;
    font-weight: 500;
    color: #009448;
  }
  .modal-footer {
    border: none;
    justify-content: flex-end !important;
    button {
      padding: 4px 20px;
      font-size: 16px;
    }
    .closeBtn {
      color: #009448;
      border: 1px solid #009448;
    }
  }
  .modal-body {
    padding-bottom: 0;
    margin: 0 20px;
    .row {
      margin-bottom: 20px;
    }
    input:not([type="checkbox"]) {
      padding: 4px 10px;
      background-color: #F6F6F6;
    }
    input[type="checkbox"] {
      border-radius: 20px;
    }
    .invalid-feedback {
      display: block !important;
    }
    .notifCol {
      display: flex;
      gap: 20px;
    }
  }
}


.changeLogoModal {
  .modal-content {
    width: 500px;
  }

  .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    padding-top: 40px;
    padding-bottom: 0;

    .currentLogo {
      background-color: #fff;
      width: 250px;
      height: 200px;
      border: 1px solid #dcdada;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;

      .logoImage {
        width: 100%;
        height: 100%;
        margin: 5px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 50%;
      }
    }

    .pdfUploaderContainer {
      width: 250px;
      border-color: #4FC878;
      justify-content: center;

      .pdf_label {
        width: auto;
        color: #4FC878;
      }
    }
  }

  .modal-footer {
    justify-content: flex-end !important;
    border: none;
    padding-top: 0;
    padding-bottom: 30px;
    padding-right: 40px;

    .saveBtn {
      background-color: #4FC878;
      color: #fff;
      padding: 2px 10px;
    }
    .cancelBtn {
      border: 1px solid #4FC878;
      color: #4FC878;
      padding: 2px 10px;
    }
  }

}


.changeBannerModal {

  .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    padding-top: 40px;
    padding-bottom: 0;

    .currentLogo {
      background-color: #fff;
      width: 450px;
      height: 200px;
      border: 1px solid #dcdada;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;

      .logoImage {
        width: 100%;
        height: 100%;
        margin: 5px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 50%;
      }
    }

    .pdfUploaderContainer {
      width: 450px;
      border-color: #4FC878;
      justify-content: center;

      .pdf_label {
        width: auto;
        color: #4FC878;
      }
    }
  }

  .modal-footer {
    justify-content: flex-end !important;
    border: none;
    padding-top: 0;
    padding-bottom: 30px;
    padding-right: 40px;

    .saveBtn {
      background-color: #4FC878;
      color: #fff;
      padding: 2px 10px;
    }
    .cancelBtn {
      border: 1px solid #4FC878;
      color: #4FC878;
      padding: 2px 10px;
    }
  }

}