
.achievementModal {
  min-height: 500px !important;

  .modal-body {
    width: 100% !important;
    height: 200px !important;
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 7px;
  }
}

.achievementClose {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 16px;
  cursor: pointer;
}

.achievementModalTitle {
  color: #2d554d;
  position: absolute;
  top: 15%;
  left: 0;
  width: 100%;
  height: auto;
  font-size: 46px;
  text-align: center;
}

.achievementModalDesc {
  color: #2d554d;
  position: absolute;
  top: 55%;
  left: 0;
  width: 100%;
  height: auto;
  font-size: 24px;
  text-align: center;
}

.achievementModalMain {
  z-index: 999999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1000%;
}

.confetti-container {
  user-select: none;
  z-index: 999;
}