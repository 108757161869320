.greenStepNotes {

  .card {
    min-height: 200px;
    transition: transform 0.5s;
    transform-style: preserve-3d;
    padding: 20px 20px 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
  }

  .note {
    text-align: center;
  }

}